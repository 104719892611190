import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "station/";
import authHeader from "../services/auth-header";

export const station = {
  state: {
    allStations: [],
    stations: [],
    station: [],
  },
  getters: {
    stations: (state) => state.stations,
    allStations: (state) => state.allStations,
    oneStations: (state) => state.station,
    // getStatusById: state => id => {
    //   console.log(state.stations)
    //     return state.stations.find(s => s._id === id);
    // }
  },
  actions: {
    async getStations({ commit }) {
      try {
        const response = await axios.get(apiURL, { headers: authHeader() });
        commit("getAllStation", response.data.data);
        return response.data.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async infiniteScroll({ commit }, data) {
      return axios
        .post(
          apiURL + "infiniteScroll",
          { lastNumber: data.last, length: data.length },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          let obj = res.data.data;
          console.log(res.data.data);
          if (obj.length > 0) {
            for (let i = 0; i < obj.length; i++) {
              obj[i].show = 0;
            }
            commit("getStation", res);
            return obj;
          } else {
            return res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    async filterStations({ commit }, data) {
      return axios
        .post(apiURL + "filter", data, {
          headers: authHeader(),
        })
        .then((res) => {
          let obj = res.data.data;
          if (obj.length > 0) {
            for (let i = 0; i < obj.length; i++) {
              obj[i].show = 0;
            }
            commit("getStation", res);
            return obj;
          } else {
            return res.data.data;
          }
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async getStationsByID({ commit }, id) {
      try {
        const response = await axios.get(apiURL + id, {
          headers: authHeader(),
        });
        //console.log(response);
        commit("getStationsByID", response.data.data);
      } catch (error) {
        //console.log(error.response.status)
        // if (error.response.status === 401) {
        //   this.dispatch("auth/logout");
        // }
        return Promise.reject(error);
      }
    },
    async createStations({ commit }, station) {
      //console.log(station);
      return axios
        .post(apiURL, station, { headers: authHeader() })
        .then((res) => {
          commit("createStation", res.data.station);
          return Promise.resolve(res.data.station);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    async updateOrder({ commit }, data) {
      return axios
        .post(apiURL + "updateOrder", data, { headers: authHeader() })
        .then((res) => {
          commit("getStation", res.data.data);
          return Promise.resolve(res.data.data);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    async updateStations({ commit }, station) {
      console.log(station);
      return axios
        .put(apiURL + station._id, station, { headers: authHeader() })
        .then((res) => {
          let station = res.data.data;
          station.show = 0;
          commit("updateStation", station);
          return Promise.resolve(station);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    deleteStations({ commit }, station) {
      return axios
        .delete(apiURL + station.id, { headers: authHeader() })
        .then(() => {
          commit("deleteStation", station);
          return Promise.resolve(station);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setAuto({ commit }, payload) {
      // console.log(payload.auto);
      return axios
        .put(apiURL + "auto/" + payload.id, payload, { headers: authHeader() })
        .then(() => {
          commit("setAuto", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setStopAll({ commit }, payload) {
      return axios
        .put(apiURL + "stopall/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setStopAll", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setNameOut({ commit }, payload) {
      return axios
        .put(apiURL + "out/name/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setNameOut", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    startTimeOut({ commit }, payload) {
      return axios
        .put(apiURL + "out/starttime/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("startTimeOut", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    stopTimeOut({ commit }, payload) {
      return axios
        .put(apiURL + "out/stoptime/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("stopTimeOut", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    buttonOut({ commit }, payload) {
      return axios
        .put(apiURL + "out/onoff/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("buttonOut", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setVolt({ commit }, payload) {
      return axios
        .put(apiURL + "setVolt/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setVolt", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setCurrent({ commit }, payload) {
      return axios
        .put(apiURL + "setCurrent/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setCurrent", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setPower({ commit }, payload) {
      return axios
        .put(apiURL + "setPower/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setPower", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    setTimeLog({ commit }, payload) {
      return axios
        .put(apiURL + "setTimeLog/" + payload.id, payload, {
          headers: authHeader(),
        })
        .then(() => {
          commit("setTimeLog", payload);
          return Promise.resolve(payload);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    getAllStation(state, station) {
      state.allstation = station;
    },
    getStation(state, station) {
      state.stations.push(station);
      const obj = JSON.parse(localStorage.getItem("online"));
      for (const i in obj) {
        const index = state.stations.findIndex(
          (s) => s.macAddress === obj[i].macAddress
        );
        if (index > -1) {
          state.stations[index].online = obj[i].online;
          state.stations[index].status = obj[i].status;
        }
      }
    },
    getStationsByID(state, station) {
      state.station = station;
      const obj = JSON.parse(localStorage.getItem("online"));
      for (const i in obj) {
        if (state.station.macAddress == obj[i].macAddress) {
          state.station.online = obj[i].online;
          state.station.status = obj[i].status;
        }
      }
    },
    createStation(state, station) {
      //state.stations.unshift(station);
    },
    updateStation(state, station) {
      const index = state.stations.findIndex((s) => s._id === station.id);
      state.stations[index] = station;
    },
    deleteStation(state, station) {
      const index = state.stations.findIndex((s) => s._id === station.id);
      state.stations.splice(index, 1);
    },
    setAuto(state, payload) {
      //const index = state.stations.findIndex(s => s._id === payload.id);
      state.station.auto = payload.auto;
    },
    setStopAll(state, payload) {
      //const index = state.stations.findIndex(s => s._id === payload.id);
      state.station.stopAll = payload.stopAll;
    },
    setNameOut(state, payload) {
      //const index = state.stations.findIndex(s => s._id === payload.id);
      state.station.outs[payload.number].name = payload.name;
    },
    startTimeOut(state, payload) {
      //const index = state.stations.findIndex(s => s._id === payload.id);
      state.station.outs[payload.number].startTime = payload.startTime;
    },
    stopTimeOut(state, payload) {
      //const index = state.stations.findIndex(s => s._id === payload.id);
      state.station.outs[payload.number].stopTime = payload.stopTime;
    },
    buttonOut(state, payload) {
      //const index = state.stations.findIndex(s => s._id === payload.id);
      state.station.outs[payload.number].manual = payload.manual;
    },
    setVolt(state, payload) {
      state.station.powerMeter[0].high = payload.high;
      state.station.powerMeter[0].low = payload.low;
    },
    setCurrent(state, payload) {
      state.station.powerMeter[1].high = payload.high;
      state.station.powerMeter[1].low = payload.low;
    },
    setPower(state, payload) {
      state.station.powerMeter[2].high = payload.high;
      state.station.powerMeter[2].low = payload.low;
    },
    setTimeLog(state, payload) {
      state.station.timeLog = payload.timeLog;
    },
    SOCKET_STATUS(state, payload) {
      const obj = JSON.parse(payload);
      //console.log(obj);
      localStorage.setItem("online", JSON.stringify(obj));
      if (state.stations.length > 0) {
        for (const i in obj) {
          const index = state.stations.findIndex(
            (s) => s.macAddress === obj[i].macAddress
          );
          if (index > -1) {
            state.stations[index].online = obj[i].online;
          }
        }
      } else {
        for (const i in obj) {
          if (state.station.macAddress == obj[i].macAddress) {
            if (state.station.status !== obj[i].status) {
              state.station.status = obj[i].status;
              state.station.outs.forEach(function(item, index) {
                if ((state.station.status & Math.pow(2, index)) > 0) {
                  item.manual = "on";
                } else {
                  item.manual = "off";
                }
              });
            }
          }
        }
      }
    },
  },
};
