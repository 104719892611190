import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "company/";
import authHeader from "../services/auth-header";

export const company = {
  state: {
    companies: [],
    departments: [],
  },
  getters: {
    allCompanies: (state) => state.companies,
    getCompanyWithDepartment: (state) => state.departments,
  },
  actions: {
    async getCompanies({ commit }) {
      try {
        const response = await axios.get(apiURL, { headers: authHeader() });
        commit("getCompanies", response.data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    createCompany({ commit }, company) {
      //console.log(company)
      return axios
        .post(apiURL, company, { headers: authHeader() })
        .then((res) => {
          commit("createCompany", res.data.company);
          return Promise.resolve(res.data.company);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    updateCompanies({ commit }, company) {
      return axios
        .put(apiURL + company.id, company, { headers: authHeader() })
        .then(() => {
          commit("updateCompanies", company);
          return Promise.resolve(company);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    deleteCompanies({ commit }, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          commit("deleteCompanies", id);
          // return Promise.resolve(user);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    getCompanyWithDepartment({ commit }, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((data) => {
          const all = { name: "Not Selected", id: "notSelect" };
          if (data.data.data.departments != undefined) {
            const department = data.data.data.departments;
            department.unshift(all);
            commit("getCompanyWithDepartment", department);
          } else {
            commit("getCompanyWithDepartment", [all]);
          }

          // return Promise.resolve(user);
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    getCompanies(state, company) {
      state.companies = company;
    },
    createCompany(state, company) {
      state.companies.unshift(company);
    },
    updateCompanies(state, company) {
      const index = state.companies.findIndex((c) => c.id === company.id);
      //console.log(index)
      //state.users[index] = user;
      state.companies.splice(index, 1, company);
    },
    deleteCompanies(state, id) {
      const index = state.companies.findIndex((c) => c.id === id);
      state.companies.splice(index, 1);
    },
    getCompanyWithDepartment(state, department) {
      state.departments = department;
    },
  },
};
