import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "event/";
import authHeader from "../services/auth-header";

export const event = {
  state: {
    events: [],
  },
  getters: {
    eventsPower: (state) => state.events,
  },
  mutations: {
    setEvent(state, events) {
      state.events = events;
    },
  },
  actions: {
    async getEventPower({ commit }, data) {
      try {
        const response = await axios.put(
          apiURL + "power/" + data.id,
          { type: data.type },
          { headers: authHeader() }
        );
        //console.log(response);
        commit("setEvent", response.data.data);
        return response.data.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
