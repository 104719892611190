<template>
  <v-container fluid>
    <v-card class="mx-auto elevation-19">
      <v-card-title>
        <h3 class="blue-grey--text">Departments</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="#2C3A47"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="getDepartments"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="success"
            class="mx-2"
            fab
            x-small
            @click="editDepartment(item)"
            outlined
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="pink"
            fab
            x-small
            @click="deleteDepartment(item.id)"
            outlined
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-results>
          <v-alert :value="true" color="pink" icon="warning" dark>
            Your Search {{ search }} does not exist.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      class="mx-2 my-10"
       :style="isMobile ? 'bottom: 50px;' : 'bottom: -20px;'"
      fixed
      fab
      color="red"
      dark
      absolute
      bottom
      right
      @click="
        close();
        opendialog();
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div id="modal">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title v-if="create" class="headline red darken-2 white--text">
            Add Department
          </v-card-title>
          <v-card-title v-else class="headline red darken-2 white--text">
            Edit Department
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="saveDepartmet"
              ref="DepartmentData"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                name="name"
                id="id"
                label="Name"
                hide-details="auto"
                v-model="DepartmentData.name"
                :rules="[v => !!v || 'Name is required']"
                required
                class="mb-6"
              ></v-text-field>
              <v-select
                :items="getCompanies"
                item-text="name"
                item-value="id"
                label="Company"
                v-model="DepartmentData.company"
                :rules="[v => !!v || 'Company is required']"
                required
              ></v-select>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="success" text @click="saveDepartment()">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar v-model="snackbar" buttom left :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false">
        close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
//import axios from "axios";
import {
  isIOS,
  isMobile,
  isAndroid,
  isSafari,
  isWinPhone,
} from "mobile-device-detect";
export default {
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getCompanies() {
      return this.$store.getters.allCompanies;
    },
    getDepartments() {
      return this.$store.getters.allDepartments;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    //console.log(this.getUsers);
    this.load();
  },
  data() {
    return {
       isMobile: (isIOS || isSafari || isAndroid || isMobile || isWinPhone),
      dialog: false,
      create: true,
      mini: false,
      fab: false,
      search: "",
      snackbar: false,
      text: "",
      color: "",
      btnName: "",
      headers: [
        { text: "Name", value: "name", sortable: true, align: "start" },
        { text: "Action", value: "actions", sortable: false, align: "center" }
      ],
      DepartmentData: {
        name: "",
        company: ""
      },
      default: {
        name: "",
        company: ""
      },
      editedIndex: -1
    };
  },
  methods: {
    opendialog() {
      this.dialog = true;
      this.create = true;
      this.isRemove = false;
    },
    load() {
      this.$store.dispatch("getDepartments");
      this.$store.dispatch("getCompanies");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.DepartmentData = Object.assign({}, this.default);
        this.editedIndex = -1;
        this.create = true;
        this.$refs.DepartmentData.resetValidation();
        this.url = "images/nopic.png";
      }, 300);
    },
    saveDepartment() {
      if (this.editedIndex > -1) {
        this.updateDepartment();
      } else {
        this.createDepartment();
      }
    },
    createDepartment() {
      if (this.$refs.DepartmentData.validate()) {
        this.$store.dispatch("createDepartment", this.DepartmentData).then(
          () => {
            this.dialog = false;
            this.load();
            this.color = "success";
            this.text = "Department has been successfully saved.";
            this.snackbar = true;
          },
          error => {
            this.color = "error";
            this.text = error.response.data.error.message;
            this.snackbar = true;
          }
        );
      }
    },
    editDepartment(item) {
      this.editedIndex = this.getDepartments.indexOf(item);
      this.DepartmentData = Object.assign({}, item);
      this.create = false;
      this.dialog = true;
    },
    updateDepartment() {
      this.$store.dispatch("updateDepartments", this.DepartmentData).then(
        () => {
          this.close();
          this.color = "info";
          this.text = "Department has been modified.";
          this.snackbar = true;
        },
        error => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    deleteDepartment(id) {
      //console.log(id);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          try {
            this.$store.dispatch("deleteDepartments", id).then(() => {});
          } catch (error) {
            console.log(error);
          }
          this.$swal("Deleted!", "Your file has been deleted.", "success");
        }
      });
    }
  }
};
</script>
