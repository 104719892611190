<template>
  <div>
    <v-bottom-navigation
      fixed
      color="white"
      background-color="#D32F2F"
      dark
      v-if="this.homePath"
    >
      <v-btn @click="dashboard()">
        <span>Dashboard</span>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn @click="event()">
        <span>Reports</span>
        <v-icon>mdi-file-document</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role === 'admin'" @click="company()">
        <span>Setting</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      fixed
      color="white"
      background-color="#D32F2F"
      dark
      v-if="this.reportPath"
    >
      <v-btn @click="dashboard()">
        <span>Dashboard</span>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn @click="event()">
        <span>Events</span>
        <v-icon>mdi-alert</v-icon>
      </v-btn>
      <v-btn @click="maintenance()">
        <span>Maintenances</span>
        <v-icon>mdi-wrench</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      fixed
      color="white"
      background-color="#D32F2F"
      dark
      v-if="this.adminPath"
    >
      <v-btn @click="dashboard()">
        <span>Dashboard</span>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role === 'admin'" @click="company()">
        <span>Company</span>
        <v-icon>mdi-domain</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role === 'admin'" @click="department()">
        <span>Department</span>
        <v-icon>mdi-sitemap</v-icon>
      </v-btn>

      <v-btn v-if="getAccess.role === 'admin'" @click="accounts()">
        <span>Accounts</span>
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
export default {
  created() {
    this.checkManu();
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  data() {
    return {
      homePath: false,
      reportPath: false,
      adminPath: false,
      path: null,
    };
  },
  watch: {
    $route(to, from) {
      //console.log(to);
      if (to.name.search("Home") === 0 || to.name.search("Chart") == 0) {
        this.homePath = true;
        this.adminPath = false;
        this.reportPath = false;
      } else if (to.name.search("Admin") > -1) {
        this.adminPath = true;
        this.homePath = false;
        this.reportPath = false;
      } else {
        this.reportPath = true;
        this.homePath = false;
        this.adminPath = false;
      }
    },
  },
  methods: {
    dashboard() {
      this.$router.push("/Home");
    },
    event() {
      this.$router.push("/event");
    },
    maintenance() {
      this.$router.push("/maintenance");
    },
    company() {
      this.$router.push("/company");
    },
    department() {
      this.$router.push("/department");
    },
    accounts() {
      this.$router.push("/accounts");
    },
    checkManu() {
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);
      if (this.path.search("Home") === 0 || this.path.search("Chart") === 0) {
        this.homePath = true;
        this.adminPath = false;
        this.reportPath = false;
      } else if (this.path.search("Admin") > -1) {
        this.adminPath = true;
        this.homePath = false;
        this.reportPath = false;
      } else {
        this.reportPath = true;
        this.homePath = false;
        this.adminPath = false;
      }
    },
  },
};
</script>
<style></style>
