import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "user/";
import authHeader from "../services/auth-header";

export const accounts = {
  state: {
    users: []
  },
  getters: {
    allUsers: state => state.users
  },
  actions: {
    async getUsers({ commit }) {
      try {
        const response = await axios.get(apiURL, { headers: authHeader() });
        //console.log(response);
        commit("getUsers", response.data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateUser({ commit }, user) {
      return axios
        .put(apiURL + user._id, user, { headers: authHeader() })
        .then(() => {
          commit("updateUser", user);
          return Promise.resolve(user);
        })
        .catch(error => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    deleteUser({ commit }, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          commit("deleteUser", id);
          // return Promise.resolve(user);
        })
        .catch(error => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    }
  },
  mutations: {
    getUsers(state, user) {
      state.users = user;
    },
    updateUser(state, user) {
      const index = state.users.findIndex(u => u._id === user._id);
      //console.log(index)
      //state.users[index] = user;
      state.users.splice(index, 1, user);
    },
    deleteUser(state, id) {
      const index = state.users.findIndex(u => u._id === id);
      state.users.splice(index, 1);
    }
  }
};
