<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      width="200"
    >
      <template v-slot:prepend v-if="getAccess.role != 'admin'">
        <v-list-item two-line>
          <v-list-item-avatar size="70">
            <img :src="getAccess.photo" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ getAccess.company }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              router
              :to="child.route"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content router :to="child.route">
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" router :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
          v-if="getAccess.role === 'admin' && homePath"
          @click="changePageAdmin()"
        >
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Admin Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="red darken-1"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">LED Billboard IOT</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn @click="changePageHome()" icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn> -->
      <v-btn text @click="changePageHome()">HOME</v-btn>

      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon> mdi-account </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ getAccess.name }}</v-list-item-title>
                <v-list-item-subtitle
                  >@{{ getAccess.username }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item @click="opendialog()">
              <v-list-item-action>
                <v-icon>mdi-key-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>Change password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-action>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-dialog v-model="dialog" width="350" persistent>
      <v-card>
        <v-card-title class="headline red darken-2 white--text">
          Change password
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="changePassword"
            ref="changePassword"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              :type="password.hideOld ? 'text' : 'password'"
              :append-icon="
                password.hideOld ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="old password"
              id="id"
              label="Old Password"
              hide-details="auto"
              v-model="password.old"
              @click:append="password.hideOld = !password.hideOld"
              :rules="[(v) => !!v || 'Old Password is required']"
              required
            ></v-text-field>
            <v-text-field
              :type="password.hideNew ? 'text' : 'password'"
              :append-icon="
                password.hideNew ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="new password"
              id="id"
              label="New Password"
              hide-details="auto"
              v-model="password.new"
              @click:append="password.hideNew = !password.hideNew"
              :rules="[(v) => !!v || 'New Password is required']"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" text @click="changePassword()">Change</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" buttom left :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    items: null,
    password: {
      old: "",
      new: "",
      hideOld: false,
      hideNew: false,
    },
    default: {
      old: "",
      new: "",
      hideOld: false,
      hideNew: false,
    },
    payload: {
      id: "",
      newPassword: "",
      password: "",
    },
    snackbar: false,
    text: "",
    color: "",
    homeManu: [
      { icon: "mdi-apps", text: "Dashboard", route: "/Home" },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Reports",
        model: false,
        children: [
          { icon: "mdi-alert", text: "Events", route: "/event" },
          { icon: "mdi-wrench", text: "Maintenances", route: "/maintenance" },
        ],
      },
    ],
    adminManu: [
      { icon: "mdi-domain", text: "Company", route: "/company" },
      { icon: "mdi-sitemap", text: "Department", route: "/department" },
      {
        icon: "mdi-account-multiple",
        text: "Accounts",
        route: "/accounts",
      },
    ],
    path: null,
    homePath: false,
  }),
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  created() {
    this.$store.dispatch("auth/decode");
    this.checkManu();
  },
  watch: {
    $route(to) {
      //console.log(to);
      if (
        to.name.search("companyAdmin") === 0 ||
        to.name.search("departmentAdmin") === 0 ||
        to.name.search("accountsAdmin") === 0
      ) {
        this.items = this.adminManu;
        this.homePath = false;
      } else {
        this.items = this.homeManu;
        this.homePath = true;
      }
    },
  },
  methods: {
    opendialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.password = Object.assign({}, this.default);
        this.$refs.changePassword.resetValidation();
      }, 300);
    },
    checkManu() {
      this.path = this.$router.currentRoute.name;
      if (this.path.search("Admin") > -1) {
        this.items = this.adminManu;
        this.homePath = false;
      } else {
        this.items = this.homeManu;
        this.homePath = true;
      }
    },
    changePageAdmin() {
      // this.items = this.adminManu;
      // this.homePath = false;
      this.$router.push("/company");
      this.checkManu();
    },
    changePageHome() {
      this.$router.push("/Home");
      this.checkManu();
    },
    changePassword() {
      if (this.$refs.changePassword.validate()) {
        this.payload.id = this.getAccess.id;
        this.payload.newPassword = this.password.new;
        this.payload.password = this.password.old;
        this.$store.dispatch("auth/changePassword", this.payload).then(
          () => {
            this.dialog = false;
            this.color = "success";
            this.text = "Success! Your Password has been changed!";
            this.snackbar = true;
          },
          (error) => {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
        );
      }
    },
    logout() {
      this.$swal({
        title: "You want to log out?",
        text: "",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            // let apiURL = `http://localhost:3000/api/station/${id}`;
            // console.log(apiURL);
            // axios.delete(apiURL).then(() => {
            //   this.loadStations();
            // });
            //console.log("dssssf")
            this.$store.dispatch("auth/logout").then(() => {
              this.$router.push("/");
            });
          } catch (error) {
            console.log(error);
          }
          //this.$swal("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
  },
};
</script>
