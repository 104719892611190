import Vue from "vue";
import Vuex from "vuex";
//import createPersistedState from "vuex-persistedstate";
import { auth } from "./auth.module";
import { chart } from "./chart.module";
import { station } from "./station.module";
import { accounts } from "./accounts.module";
import { company } from "./company.module";
import { department } from "./department.module";
import { event } from "./events.module"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    chart,
    event,
    station,
    accounts,
    company,
    department
  }
  // plugins: [createPersistedState()]
});
