import ChartService from "../services/chart.service";
export const chart = {
  state: {
    chart: {},
    dataExport: {},
  },
  getters: {
    charts: (state) => state.chart,
    dataExport: (state) => state.dataExport,
  },
  mutations: {
    setChart(state, chart) {
      state.chart = chart;
    },
    setData(state, dataExport) {
      state.dataExport = dataExport;
    },
  },
  actions: {
    getChart({ commit }, data) {
      //console.log(data);
      return ChartService.getChart(data).then(
        (data) => {
          console.log(data);
          commit("setChart", data);
          return data;
        },
        (error) => {
          //console.log(error);
          return error
        }
      );
    },
    getdataExport({ commit }, id) {
      return ChartService.getDataChart(id).then(
        (data) => {
          //console.log(data);
          commit("setData", data);
          return data;
        },
        (error) => {
          //console.log(error);
          return error
        }
      );
    },
    clearData({ commit }, id) {
      return ChartService.clearData(id).then(
        (data) => {
          //console.log(data);
          return data;
        },
        (error) => {
          //console.log(error);
          return error
        }
      );
    },
  },
};
