import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "department/";
import authHeader from "../services/auth-header";

export const department = {
  state: {
    departments: []
  },
  getters: {
    allDepartments: state => state.departments
  },
  actions: {
    async getDepartments({ commit }) {
      try {
        const response = await axios.get(apiURL, { headers: authHeader() });
        //console.log(response);
        commit("getDepartments", response.data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    createDepartment({ commit }, department) {
      //console.log(company)
      return axios
        .post(apiURL, department, { headers: authHeader() })
        .then(res => {
          commit("createDepartment", res.data.department);
          return Promise.resolve(res.data.department);
        })
        .catch(error => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    updateDepartments({ commit }, department) {
      return axios
        .put(apiURL + department._id, department, { headers: authHeader() })
        .then(() => {
          commit("updateDepartments", department);
          return Promise.resolve(department);
        })
        .catch(error => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    deleteDepartments({ commit }, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          commit("deleteDepartments", id);
          // return Promise.resolve(user);
        })
        .catch(error => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    }
  },
  mutations: {
    getDepartments(state, department) {
      state.departments = department;
    },
    createDepartment(state, department) {
      state.departments.unshift(department);
    },
    updateDepartments(state, department) {
      const index = state.departments.findIndex(d => d.id === department.id);
      //console.log(index)
      //state.users[index] = user;
      state.departments.splice(index, 1, department);
    },
    deleteDepartments(state, id) {
      const index = state.departments.findIndex(d => d.id === id);
      state.departments.splice(index, 1);
    }
  }
};
