import axios from "axios";
import authHeader from "../services/auth-header";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
const API_URL = process.env.VUE_APP_API_URL + "user/";

class AuthService {
  login(user) {
    console.log(user);
    return axios
      .post(API_URL + "login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          //localStorage.setItem("user", JSON.stringify(response.data));
          Cookies.set("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    Cookies.remove("user")
    //localStorage.removeItem("user");
  }

  register(user) {
    return axios.post(
      API_URL + "register",
      {
        username: user.username,
        name: user.name,
        password: user.password,
        role: user.role,
        company: user.company,
        department: user.department,
      },
      { headers: authHeader() }
    );
  }

  changePassword(payload) {
    return axios.put(
      API_URL + "changePassword/" + payload.id,
      {
        newPassword: payload.newPassword,
        password: payload.password,
      },
      { headers: authHeader() }
    );
  }

  resetPassword(payload) {
    return axios.put(
      API_URL + "resetPassword/" + payload.id,
      {
        newPassword: payload.newPassword,
      },
      { headers: authHeader() }
    );
  }

  jwtDecode() {
    const user = JSON.parse(Cookies.get("user"));
    return jwt_decode(user.accessToken);
  }

  active(payload) {
    return axios.put(
      API_URL + "active/" + payload.id,
      {
        active: payload.active,
      },
      { headers: authHeader() }
    );
  }
}

export default new AuthService();
