import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Controls from "../views/Controls.vue";
import event from "../views/event_report.vue";
import maintenance from "../views/maintenance_report.vue";
import login from "../views/Login.vue";
import Accounts from "../views/Accounts.vue";
import ResetPass from "../views/ResetPassword.vue";
import Company from "../views/Company.vue";
import Departments from "../views/Departments.vue";
import Chart from "../views/Chart.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/Home",
    name: "Home",
    component: Home
  },
  {
    path: "/Controls",
    name: "Controls",
    component: Controls
  },
  {
    path: "/event",
    name: "event",
    component: event
  },
  {
    path: "/chart",
    name: "Chart",
    component: Chart
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: maintenance
  },
  {
    path: "/accounts",
    name: "accountsAdmin",
    component: Accounts
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPass,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/company",
    name: "companyAdmin",
    component: Company
  },
  {
    path: "/department",
    name: "departmentAdmin",
    component: Departments
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
