<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        <v-row>
          <v-col md="3" cols="12" class="mt-6"> Event Report</v-col>
          <v-spacer></v-spacer>
          <v-col md="3" cols="12">
            <v-select
              :items="station_data"
              item-text="name"
              item-value="_id"
              label="Station Name"
              v-model="search_data._id"
            ></v-select>
          </v-col>
          <v-col md="2" cols="6" class="d-flex flex-row-reverse">
            <v-dialog
              ref="dialog_start"
              v-model="modal_start"
              :return-value.sync="search_data.startDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="search_data.startDate"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_data.startDate"
                scrollable
                :max="search_data.endDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal_start = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog_start.save(search_data.startDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col md="2" cols="6" class="d-flex flex-row-reverse">
            <v-dialog
              ref="dialog_end"
              v-model="modal_end"
              :return-value.sync="search_data.endDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="search_data.endDate"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_data.endDate"
                scrollable
                :min="search_data.startDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal_end = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog_end.save(search_data.endDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col md="2" cols="12" class="d-flex flex-row-reverse">
            <v-btn block color="error" class="mt-4" @click="searchData()"
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          ref="myPdfComponent"
          :headers="headers"
          :items="datas"
          :items-per-page="5"
          class="elevation-1"
        >
        </v-data-table>
        <br />
        <v-btn color="success">
          <download-excel
            :data="datas"
            :fields="columns"
            type="excel"
            name="event_report.xls"
          >
            Download Excel
          </download-excel>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import authHeader from "../services/auth-header";
// Vue.filter("formatDate", function(value) {
//   if (value) {
//     return moment(String(value)).format("MM/DD/YYYY hh:mm");
//   }
// });

Vue.component("downloadExcel", JsonExcel);

export default {
  components: {},
  data() {
    return {
      columns: {
        "Create At": "date",
        Station: "station.name",
        Description: "description",
        User: "user",
      },
      headers: [
        { text: "Create At", value: "date" }, //{{ createdAt | format_date }}
        { text: "Station", value: "station.name" },
        { text: "Description", value: "description" },
        { text: "User", value: "user" },
      ],
      search_data: {},
      modal_start: false,
      modal_end: false,
      datas: [],
      station_data: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getStations() {
      return this.$store.getters.allStations;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    //let dashboard = await axios.get("http://localhost:3000/api/station");
    this.$store.dispatch("getStations").then((res) => {
      this.station_data = res;
    });
    // this.station_name = dashboard.data.data;s
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    },
    searchData() {
      console.log(this.search_data);
      let apiURL = process.env.VUE_APP_API_URL + `event/`;
      axios
        .put(
          apiURL,
          {
            id: this.search_data._id || "",
            startDate: this.search_data.startDate,
            endDate: this.search_data.endDate,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          this.datas = res.data.data;
        })
        .catch((error) => {
          console.log(error.responses);
        });
    },
  },
};
</script>
