<template>
  <div>
    <v-container fluidd>
      <v-layout row>
        <v-flex md6>
          <h1 class="ml-2 mt-1">{{ station.name }}</h1>
        </v-flex>
        <v-flex md3>
          <v-switch
            v-show="station.autoString"
            v-model="auto"
            @click="setAuto(auto)"
            inset
            :label="`${station.autoString} MODE`"
          >
          </v-switch>
        </v-flex>
        <v-flex md3>
          <v-btn
            v-show="station.autoString"
            class="mt-3"
            outlined
            color="indigo"
            @click="setStopAll"
            >STOP ALL</v-btn
          >
        </v-flex>
      </v-layout>
      <!-- <v-row>
        <v-col cols="6" md="2" class="d-flex flex-row-reverse"
          ><v-switch
            v-model="station.auto"
            @click="setAuto(station.auto)"
            inset
            :label="`${station.autoString} MODE`"
          ></v-switch
        ></v-col>
        <v-col cols="4" md="2"
          ><v-btn block class="mt-3" outlined color="indigo" @click="setStopAll"
            >STOP ALL</v-btn
          ></v-col
        >
      </v-row> -->
      <v-row justify="start">
        <v-col v-for="(item, index) in data_outs" :key="index" cols="12" md="4">
          <v-card>
            <v-alert outlined color="red darken-2">
              <v-card-title dark color="red darken-3">
                <template v-if="index < station.type">
                  <h3 v-if="index < 8">CPU DQ a.{{ index.toString() }}</h3>
                  <h3 v-else>CPU DQ b.{{ index - 8 }}</h3>
                </template>
                <template
                  v-else-if="station.type + 8 > index && index >= station.type"
                >
                  <h3>Extension DQ a.{{ index - station.type }}</h3>
                </template>
                <template v-else>
                  <h3>Extension DQ b.{{ index - (station.type + 8) }}</h3>
                </template>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-icon
                      class="mt-3 ml-1"
                      large
                      :color="
                        (getStation.status & Math.pow(2, index)) > 0
                          ? 'green'
                          : 'red'
                      "
                      >mdi-checkbox-blank-circle</v-icon
                    >
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                      v-model="item.name"
                      v-bind:label="index.toString()"
                      @change="setName(index)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-dialog
                      ref="startTimedialog"
                      v-model="item.startTimeModal"
                      :return-value.sync="item.startTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.startTime"
                          label="Start Time"
                          prepend-icon="mdi-clock"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!auto"
                          @click:clear="clearStartTime(index)"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="item.startTimeModal"
                        v-model="item.startTime"
                        full-width
                        format="24hr"
                        use-seconds
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="item.startTimeModal = false"
                          >Cancel</v-btn
                        >
                        <v-btn text color="primary" @click="startTime(index)"
                          >OK</v-btn
                        >
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="stopTimedialog"
                      v-model="item.stopTimeModal"
                      :return-value.sync="item.stopTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.stopTime"
                          label="Stop Time"
                          prepend-icon="mdi-clock"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!auto"
                          @click:clear="clearStopTime(index)"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="item.stopTimeModal"
                        v-model="item.stopTime"
                        full-width
                        format="24hr"
                        use-seconds
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="item.stopTimeModal = false"
                          >Cancel</v-btn
                        >
                        <v-btn text color="primary" @click="stopTime(index)"
                          >OK</v-btn
                        >
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      class="mt-1"
                      block
                      :disabled="auto"
                      v-if="item.manual === 'on'"
                      color="success"
                      >On</v-btn
                    >
                    <v-btn
                      class="mt-1"
                      block
                      :disabled="auto"
                      v-if="item.manual === 'off'"
                      outlined
                      color="indigo"
                      @click="buttonOn(index)"
                      >On</v-btn
                    >
                  </v-col>

                  <v-col cols="6">
                    <v-btn
                      block
                      class="ml-1 mt-1"
                      :disabled="auto"
                      v-if="item.manual === 'on'"
                      outlined
                      color="indigo"
                      @click="buttonOff(index)"
                      >Off</v-btn
                    >
                    <v-btn
                      block
                      class="ml-1 mt-1"
                      :disabled="auto"
                      v-if="item.manual === 'off'"
                      color="error"
                      >Off</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import child from "./Home.vue"
//import axios from "axios";
import Cookies from "js-cookie"
export default {
  data() {
    return {
      data_outs: [],
      topic: [],
      station: [],
      stationID: "",
      auto: true
    };
  },
  components: {
    // child
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getStation() {
      return this.$store.getters.oneStations;
    }
    // getStatus() {
    //   return this.$store.getters.getStatusById(this.stationID).status || 0;
    // }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    //console.log(this.getStatus());
    this.loadPosts();
    //console.log(this.getStatus());
  },
  created() {
    if (this.$route.params.data != null) {
      this.station = this.$route.params.data;
      this.data_outs = this.$route.params.data.outs;
      Cookies.set("stationID", JSON.stringify(this.station._id));
      this.setAutoString(this.station.auto);
    }
  },
  methods: {
    loadPosts: async function() {
      this.stationID = JSON.parse(Cookies.get("stationID"));
      //let apiURL = `http://localhost:3000/api/station/${this.stationID}`;

      this.$store.dispatch("getStationsByID", this.stationID).then(() => {
        //this.showstation = this.$store.getters.getStationById(this.stationID);
        //console.log(this.$store.state.station.station)

        console.log(this.getStation);
        const showstation = this.getStation;
        this.station = showstation;
        this.data_outs = showstation.outs;
        this.setAutoString(this.station.auto);
      });
      //console.log(this.getStations)
      //let showstation = await axios.get(apiURL);
      //const showstation = this.$store.getters.getStationById(this.stationID);
      // this.station = showstation;
      // this.data_outs = showstation.outs;
      // this.setAutoString(this.station.auto);
    },
    setAutoString(auto) {
      if (auto == "on") {
        this.station.autoString = "AUTO";
        this.auto = true;
      } else {
        this.station.autoString = "MANUAL";
        this.auto = false;
      }
    },
    setAuto(autoBoolean) {
      console.log(autoBoolean);
      if (autoBoolean) {
        this.station.autoString = "AUTO";
        this.station.auto = "on";
      } else {
        this.station.autoString = "MANUAL";
        this.station.auto = "off";
      }
      // this.station.autoTopic = `${this.station.macAddress}/main/auto`;
      let payload = {
        id: this.station._id,
        auto: this.station.auto
      };
      this.$store.dispatch("setAuto", payload);
      // let apiURL = `http://localhost:3000/api/station/auto/${this.station._id}`;
      // axios
      //   .put(apiURL, {
      //     // "name": this.data_outs[index].name,
      //     auto: this.station.auto
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // // this.$mqtt.publish(this.station.autoTopic, this.station.autoString)
      // this.saveData();
    },
    setStopAll() {
      this.station.stopAll = "on";
      //this.station.stopAllTopic = `${this.station.macAddress}/main/stop_all`;
      //console.log(this.data_outs.length);
      // this.$mqtt.publish(this.station.stopAllTopic, this.station.stopAll)

      let payload = {
        id: this.station._id,
        stopAll: this.station.stopAll
      };
      this.$store.dispatch("setStopAll", payload);

      // let apiURL = `http://192.168.2.35:3000/api/station/stopall/${this.station._id}`;
      // axios
      //   .put(apiURL, {
      //     // "name": this.data_outs[index].name,
      //     stopAll: this.station.stopAll,
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      setTimeout(
        function() {
          this.station.stopAll = "off";

          let payload = {
            id: this.station._id,
            stopAll: this.station.stopAll
          };
          this.$store.dispatch("setStopAll", payload);

          // this.$mqtt.publish(this.station.stopAllTopic, this.station.stopAll)

          // let apiURL = `http://192.168.2.35:3000/api/station/stopall/${this.station._id}`;
          // axios
          //   .put(apiURL, {
          //     // "name": this.data_outs[index].name,
          //     stopAll: this.station.stopAll,
          //   })
          //   .then((res) => {
          //     console.log(res);
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });

          if (this.station.auto == "off") {
            for (let index = 0; index < this.data_outs.length; index++) {
              this.buttonOff(index);
            }
          }
        }.bind(this),
        3000
      );
    },
    setName(index) {
      this.$nextTick(() => {
        let payload = {
          id: this.station._id,
          name: this.data_outs[index].name,
          number: index
        };
        this.$store.dispatch("setNameOut", payload);

        // let apiURL = `http://192.168.2.35:3000/api/station/out/name/${this.station._id}`;
        // axios
        //   .put(apiURL, {
        //     name: this.data_outs[index].name,
        //     number: index,
        //   })
        //   .then((res) => {
        //     console.log(res);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        // this.saveData();
      });
    },
    startTime(index) {
      this.$nextTick(() => {
        this.$refs.startTimedialog[index].save(this.data_outs[index].startTime);

        let payload = {
          id: this.station._id,
          startTime: this.data_outs[index].startTime,
          number: index
        };
        console.log(payload);
        this.$store.dispatch("startTimeOut", payload);

        // let apiURL = `http://192.168.2.35:3000/api/station/out/starttime/${this.station._id}`;
        // axios
        //   .put(apiURL, {
        //     // "name": this.data_outs[index].name,
        //     startTime: this.data_outs[index].startTime,
        //     number: index,
        //   })
        //   .then((res) => {
        //     console.log(res);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        // this.saveData();
      });
    },
    stopTime(index) {
      this.$nextTick(() => {
        this.$refs.stopTimedialog[index].save(this.data_outs[index].stopTime);

        let payload = {
          id: this.station._id,
          stopTime: this.data_outs[index].stopTime,
          number: index
        };
        this.$store.dispatch("stopTimeOut", payload);

        // let apiURL = `http://192.168.2.35:3000/api/station/out/stoptime/${this.station._id}`;
        // axios
        //   .put(apiURL, {
        //     // "name": this.data_outs[index].name,
        //     stopTime: this.data_outs[index].stopTime,
        //     number: index,
        //   })
        //   .then((res) => {
        //     console.log(res);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        // this.saveData();
      });
    },
    buttonOn(index) {
      this.data_outs[index].manual = "on";

      let payload = {
        id: this.station._id,
        manual: this.data_outs[index].manual,
        number: index
      };
      this.$store.dispatch("buttonOut", payload);

      // let apiURL = `http://192.168.2.35:3000/api/station/out/onoff/${this.station._id}`;
      // axios
      //   .put(apiURL, {
      //     manual: this.data_outs[index].manual,
      //     number: index,
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // this.saveData();
    },
    buttonOff(index) {
      this.data_outs[index].manual = "off";

      let payload = {
        id: this.station._id,
        manual: this.data_outs[index].manual,
        number: index
      };
      this.$store.dispatch("buttonOut", payload);

      // let apiURL = `http://192.168.2.35:3000/api/station/out/onoff/${this.station._id}`;
      // axios
      //   .put(apiURL, {
      //     // "name": this.data_outs[index].name,
      //     manual: this.data_outs[index].manual,
      //     number: index,
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // this.saveData();
    },
    clearStartTime(index) {
      let payload = {
        id: this.station._id,
        startTime: "",
        number: index
      };
      this.$store.dispatch("startTimeOut", payload);
    },
    clearStopTime(index) {
      let payload = {
        id: this.station._id,
        stopTime: "",
        number: index
      };
      this.$store.dispatch("stopTimeOut", payload);
    }
  }
};
</script>
