import getStorage from "../services/auth-expire";
export default function authHeader() {
  ///let user = JSON.parse(localStorage.getItem("user"));
  let user = getStorage("user");

  //const vuex = JSON.parse(localStorage.getItem("vuex"));
  //const user = vuex.auth.user;

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return {};
  }
}
