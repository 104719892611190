<template>
  <v-container fluid>
    <v-card v-if="getChart.series != undefined && station != null">
      <v-card-title>
        <v-row justify="center" align="center">
          <v-col>
            <h3>Station : {{ getStation.name }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs color="red accent-4" left>
        <v-tab>CHART</v-tab>
        <v-tab>EVENT</v-tab>
        <v-tab-item key="0">
          <v-card elevation="0" class="mx-auto pb-2">
            <div>
              <v-row class="mx-1" align="center">
                <v-col align="start">
                  <v-btn-toggle
                    v-model="toggle_type"
                    dense
                    color="red"
                    class="mr-2"
                  >
                    <v-btn small @click="getdataChart('Voltage')">
                      VOLTAGE
                    </v-btn>

                    <v-btn small @click="getdataChart('Current')">
                      CURRENT
                    </v-btn>

                    <v-btn small @click="getdataChart('Power')"> POWER </v-btn>
                  </v-btn-toggle>
                  <v-btn-toggle
                    v-model="toggle_time"
                    dense
                    color="red"
                    class="mt-2"
                  >
                    <v-btn small @click="updateData('1d')"> 1D </v-btn>

                    <v-btn small @click="updateData('7d')"> 7D </v-btn>

                    <v-btn small @click="updateData('all')"> All </v-btn>
                  </v-btn-toggle>
                  <h4 class="mt-2">
                    Time Stamp: {{ this.station.timeLog }} minute
                  </h4> </v-col
                ><v-col align="end" v-if="getAccess.role === 'admin'">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        fab
                        elevation="4"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        @click="dataTime"
                      >
                        <v-icon color="red"> mdi-timer-cog </v-icon>
                      </v-btn>
                    </template>
                    <span>Time Stamp</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        elevation="4"
                        fab
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        @click="setDelete"
                      >
                        <v-icon color="red"> mdi-trash-can </v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-card class="my-3" elevation="0">
                <div style="height: 80vh; padding: 35px;">
                  <ChartData :data="getChart" />
                </div>
              </v-card>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item key="1">
          <v-card elevation="0" class="mx-auto pb-2">
            <v-row class="mx-1" align="center">
              <v-col align="start">
                <v-btn-toggle v-model="toggle_event" dense color="red">
                  <v-btn small @click="getdataEvent('Voltage')">
                    VOLTAGE
                  </v-btn>

                  <v-btn small @click="getdataEvent('Current')">
                    CURRENT
                  </v-btn>

                  <v-btn small @click="getdataEvent('Power')"> POWER </v-btn>
                </v-btn-toggle>
                <h4 class="mt-2">
                  High {{ this.eventdata.type }} :
                  {{ station.powerMeter[toggle_event].high }}
                  {{ this.unit }} <br />
                  Low {{ this.eventdata.type }} :
                  {{ station.powerMeter[toggle_event].low }}
                  {{ this.unit }}
                </h4>
              </v-col>
              <v-col align="end">
                <v-tooltip top v-if="getAccess.role === 'admin'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      fab
                      elevation="4"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      @click="dataSet"
                    >
                      <v-icon color="red"> mdi-tune-vertical-variant </v-icon>
                    </v-btn>
                  </template>
                  <span>Setting</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      fab
                      elevation="4"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                    >
                      <download-excel
                        :data="getEvent"
                        :fields="columns"
                        type="excel"
                        :name="nameSave"
                      >
                        <v-icon color="red"> mdi-arrow-collapse-down </v-icon>
                      </download-excel>
                    </v-btn>
                  </template>
                  <span>Download</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="getEvent"
              :items-per-page="5"
              class="ma-2"
            >
              <template v-slot:[`item.phase[0]`]="{ item }">
                {{ item.phase[0] }} {{ unit }}
              </template>
              <template v-slot:[`item.phase[1]`]="{ item }">
                {{ item.phase[1] }} {{ unit }}
              </template>
              <template v-slot:[`item.phase[2]`]="{ item }">
                {{ item.phase[2] }} {{ unit }}
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <div v-else height="200px">
      <v-row justify="center">
        <v-progress-circular indeterminate color="red"></v-progress-circular>
      </v-row>
    </div>
    <v-dialog v-model="dialog_set" width="350" persistent>
      <v-card>
        <v-card-title class="body red darken-2 white--text justify-center">
          Set {{ this.eventdata.type }} High/Low
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="savePost"
            ref="postData"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-row align="center" class="mt-2">
              <v-col align="start">
                <h3>{{ this.eventdata.type }} High:</h3>
              </v-col>
              <v-col class="pa-0">
                <v-text-field
                  :label="this.labelHigh"
                  hide-details="auto"
                  v-model="dataEdit.high"
                  :rules="[(v) => !!v || 'Power High is required']"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-2">
              <v-col align="start">
                <h3>{{ this.eventdata.type }} Low:</h3>
              </v-col>
              <v-col class="pa-0">
                <v-text-field
                  :label="this.labelLow"
                  hide-details="auto"
                  v-model="dataEdit.low"
                  :rules="[(v) => !!v || 'Power Low is required']"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" text @click="editHighLow()">Update</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_time" width="300" persistent>
      <v-card>
        <v-card-title class="boby red darken-2 white--text justify-center">
          Set Time Stamp
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="savePost"
            ref="postData"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-row align="center" class="mt-2">
              <v-col align="start">
                <h3>Time Stamp:</h3>
              </v-col>
              <v-col class="pa-0">
                <v-text-field
                  label="Time Stamp (minute)"
                  hide-details="auto"
                  v-model="dataEdit.timeLog"
                  :rules="[(v) => !!v || 'Time Stamp is required']"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="success" text @click="editTime()">Update</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_delete" width="400" persistent>
      <v-card>
        <v-card-title class="boby red darken-2 white--text justify-center">
          Delete Data
        </v-card-title>
        <v-card-text>
          <v-row align="center" class="mt-2">
            <v-col align="center">
              <h3>Please download your data before you delete!</h3>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="mt-2">
            <v-btn color="success" v-if="!download" @click="exportToExcel()"
              ><v-icon left> mdi-arrow-collapse-down </v-icon>Download</v-btn
            >
            <v-btn color="error" @click="deleteData()" v-else
              ><v-icon left>mdi-trash-can</v-icon>Delete</v-btn
            >
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" buttom left :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Cookies from "js-cookie";
import ChartData from "../components/chart.vue";
import JsonExcel from "vue-json-excel";
import XLSX from "xlsx";
import Vue from "vue";
Vue.component("downloadExcel", JsonExcel);
export default {
  created() {
    if (this.$route.params.data != null) {
      this.station = this.$route.params.data;
      Cookies.set("stationID", JSON.stringify(this.station._id));
    }
    this.loadPage();
  },
  components: {
    ChartData,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    nameSave() {
      return this.chartdata.nameStation + "_event_" + this.eventdata.type;
    },
    getStation() {
      return this.$store.getters.oneStations;
    },
    getChart() {
      return this.$store.getters.charts;
    },
    getdataExport() {
      return this.$store.getters.dataExport;
    },
    getEvent() {
      //console.log(this.$store.getters.eventsPower);
      return this.$store.getters.eventsPower;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
  },
  data() {
    return {
      download: false,
      dataChart: null,
      snackbar: false,
      text: null,
      color: null,
      labelLow: null,
      labelHigh: null,
      unit: "V",
      dialog_time: false,
      dialog_set: false,
      dialog_delete: false,
      toggle_type: 0,
      toggle_time: 0,
      toggle_event: 0,
      station: null,
      stationID: null,
      chartdata: {
        station: null,
        nameStation: null,
        type: "Voltage",
        day: "1d",
      },
      eventdata: {
        station: null,
        type: "Voltage",
      },
      dataEdit: {
        text: null,
        id: null,
        timeLog: null,
        high: null,
        low: null,
        name: null,
      },
      headers: [
        { text: "Create At", value: "date", width: "20%" }, //{{ createdAt | format_date }}
        { text: "Time PLC", value: "timePLC", width: "15%" },
        { text: "Event", value: "description" },
        { text: "Phase 1", value: "phase[0]", width: "10%", align: "center" },
        { text: "Phase 2", value: "phase[1]", width: "10%", align: "center" },
        { text: "Phase 3", value: "phase[2]", width: "10%", align: "center" },
      ],
      columns: {
        "Create At": "date",
        "Time PLC": "timePLC",
        Description: "description",
        "Phase 1": {
          field: "phase",
          callback: (value) => {
            return `${value[0]}`;
          },
        },
        "Phase 2": {
          field: "phase",
          callback: (value) => {
            return `${value[1]}`;
          },
        },
        "Phase 3": {
          field: "phase",
          callback: (value) => {
            return `${value[2]}`;
          },
        },
      },
    };
  },
  methods: {
    loadPage: async function() {
      this.stationID = JSON.parse(Cookies.get("stationID"));
      this.chartdata.station = this.stationID;
      this.eventdata.id = this.stationID;
      this.$store.dispatch("getStationsByID", this.stationID).then(() => {
        //console.log(this.getStation);
        const showstation = this.getStation;
        this.chartdata.nameStation = showstation.name;
        this.station = showstation;
      });
      this.$store.dispatch("getChart", this.chartdata);
      this.$store.dispatch("getEventPower", this.eventdata).then(() => {});
      this.dataEdit.id = this.stationID;
    },
    async setDelete() {
      await this.$store.dispatch("getdataExport", this.stationID).then(() => {
        this.dialog_delete = true;
      });
    },
    exportToExcel() {
      let voltage = XLSX.utils.json_to_sheet(this.getdataExport.voltage);
      let current = XLSX.utils.json_to_sheet(this.getdataExport.current);
      let power = XLSX.utils.json_to_sheet(this.getdataExport.power);
      let wb = XLSX.utils.book_new(); // make Workbook of Excel
      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, voltage, "Voltage"); // sheetAName is name of Worksheet
      XLSX.utils.book_append_sheet(wb, current, "Current");
      XLSX.utils.book_append_sheet(wb, power, "Power");
      // export Excel file
      XLSX.writeFile(wb, `${this.station.name}_data_powermeter.xlsx`); // name of the file is 'book.xlsx'
      this.download = true;
    },
    deleteData() {
      this.$store.dispatch("clearData", this.stationID).then(
        () => {
          this.color = "success";
          this.text = "Delete has been successfully";
          this.snackbar = true;
          this.close();
        },
        (error) => {
          this.text = error.message;
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    getdataChart(data) {
      this.chartdata.type = data;
      this.chartdata.day = "1d";
      this.toggle_time = 0;
      this.$store.dispatch("getChart", this.chartdata).then(() => {
        this.updateData("1d");
      });
    },
    getdataEvent(data) {
      this.eventdata.type = data;
      this.checkUnit(data);
      this.$store.dispatch("getEventPower", this.eventdata);
    },
    checkUnit(data) {
      switch (data) {
        case "Voltage":
          this.unit = "V";
          break;
        case "Current":
          this.unit = "A";
          break;
        case "Power":
          this.unit = "W";
          break;
      }
    },
    checkType(data) {
      switch (data) {
        case 0:
          this.dataEdit.text = "Voltage";
          break;
        case 1:
          this.dataEdit.text = "Current";
          break;
        case 2:
          this.dataEdit.text = "Power";
          break;
      }
    },
    updateData: function(timeline) {
      //console.log(new Date());
      switch (timeline) {
        case "1d":
          this.chartdata.day = "1d";
          this.$store.dispatch("getChart", this.chartdata);
          break;
        case "7d":
          this.chartdata.day = "7d";
          this.$store.dispatch("getChart", this.chartdata);
          break;
        case "all":
          this.chartdata.day = "all";
          this.$store.dispatch("getChart", this.chartdata);
          break;
        default:
      }
    },
    dataSet() {
      this.dialog_set = true;
      this.checkType(this.toggle_event);
      this.dataEdit.high = this.station.powerMeter[this.toggle_event].high;
      this.dataEdit.low = this.station.powerMeter[this.toggle_event].low;
      this.labelLow = this.dataEdit.text + " Low " + "(" + this.unit + ")";
      this.labelHigh = this.dataEdit.text + " High " + "(" + this.unit + ")";
    },
    dataTime() {
      this.dialog_time = true;
      this.dataEdit.timeLog = this.station.timeLog;
    },
    close() {
      this.dialog_time = false;
      this.dialog_set = false;
      this.dialog_delete = false;
    },
    editTime() {
      //console.log(this.dataEdit);
      if (this.dataEdit.timeLog == null || this.dataEdit.timeLog == "") {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! please fill out this form";
      } else {
        this.$store.dispatch("setTimeLog", this.dataEdit).then(
          () => {
            this.color = "success";
            this.text = "Update has been successfully";
            this.snackbar = true;
            this.close();
          },
          (error) => {
            this.text = error.message;
            this.color = "red";
            this.snackbar = true;
          }
        );
      }
    },
    editHighLow() {
      //console.log(this.dataEdit);
      switch (this.dataEdit.text) {
        case "Voltage":
          this.$store.dispatch("setVolt", this.dataEdit).then(
            () => {
              this.color = "success";
              this.text = "Update has been successfully";
              this.snackbar = true;
              this.close();
            },
            (error) => {
              this.text = error.message;
              this.color = "red";
              this.snackbar = true;
            }
          );
          break;
        case "Current":
          this.$store.dispatch("setCurrent", this.dataEdit).then(
            () => {
              this.color = "success";
              this.text = "Update has been successfully";
              this.snackbar = true;
              this.close();
            },
            (error) => {
              this.text = error.message;
              this.color = "red";
              this.snackbar = true;
            }
          );
          break;
        case "Power":
          this.$store.dispatch("setPower", this.dataEdit).then(
            (res) => {
              this.color = "success";
              this.text = "Update has been successfully";
              this.snackbar = true;
              this.close();
            },
            (error) => {
              this.text = error.message;
              this.color = "red";
              this.snackbar = true;
            }
          );
          break;
      }
    },
  },
};
</script>

<style></style>
