<template>
  <v-layout v-resize="onResize" column>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="mt-0 pt-0">
          <v-card>
            <v-row class="mx-1" align="center" justify="center">
              <v-col cols="12" xs="12" sm="12" md="8" lg="8">
                <v-row>
                  <v-col cols="12">
                    <v-btn-toggle v-model="toggle" dense color="red">
                      <v-btn class="text-upper" small> dashboard </v-btn>
                      <v-btn class="text-upper" small> table </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                    <v-text-field
                      v-model="search"
                      class="mb-0"
                      label="Search"
                      dense
                      outlined
                      color="red"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="5"
                    xs="4"
                    sm="4"
                    md="3"
                    lg="3"
                    v-if="getAccess.role === 'admin'"
                  >
                    <v-select
                      v-model="filterData.province"
                      class="mb-0"
                      :items="provinceSelect"
                      label="Province"
                      color="red"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="5"
                    xs="4"
                    sm="4"
                    md="3"
                    lg="3"
                    v-if="getAccess.role === 'admin'"
                  >
                    <v-select
                      :items="getSearchCompanies"
                      v-model="filterData.company"
                      item-text="name"
                      item-value="id"
                      class="mb-0"
                      label="Company"
                      color="red"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="2"
                    xs="2"
                    sm="2"
                    md="1"
                    lg="1"
                    v-if="getAccess.role === 'admin'"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#D63636"
                          fab
                          small
                          dark
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="searchDate()"
                        >
                          <v-icon> mdi-magnify </v-icon>
                        </v-btn>
                      </template>
                      <span>Search</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-card outlined>
                  <h2 class="ml-3 mt-2">Total</h2>
                  <h2 class="mb-3 red--text text-center">
                    {{ allStations.length }}
                    <span class="h2 black--text"> Stations</span>
                  </h2>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <draggable
        v-model="stations"
        justify="start"
        class="row"
        v-if="
          toggle == 0 &&
            filter == false &&
            search == '' &&
            getAccess.role === 'admin'
        "
        v-bind="dragOptions"
        @start="drag = true"
        @end="checkMove"
        handle=".handle"
      >
        <v-col
          v-for="(item, index) in stations"
          :key="index"
          xs="12"
          sm="12"
          md="3"
          lg="3"
        >
          <v-card color="white">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        size="25"
                        :color="item.online ? 'green' : 'red'"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <router-link
                          :to="{ name: 'Controls', params: { data: item } }"
                          style="color: inherit; text-decoration: none"
                        >
                          <!-- <v-icon large color="white">mdi-lightbulb-on</v-icon> -->
                        </router-link>
                      </v-avatar> </template
                    ><span>{{ item.online ? "Online" : "Offline" }}</span>
                  </v-tooltip>

                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                  MAC Address : {{ item.macAddress }} <br />
                  TimePLC : {{ item.timePLC }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar tile size="20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="handle"
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      >mdi-reorder-horizontal</v-icon
                    >
                  </template>
                  <span>Move</span>
                </v-tooltip>
              </v-list-item-avatar>
            </v-list-item>
            <v-col align="end" class="mt-0 py-0">
              <v-btn x-small rounded @click="openDetail(item)"
                >More Detail</v-btn
              >
            </v-col>

            <v-card-actions justify="center" align="center" class="pt-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <router-link
                      :to="{ name: 'Controls', params: { data: item } }"
                      style="color: inherit; text-decoration: none"
                    >
                      <v-icon small>mdi-lightbulb-on</v-icon>
                    </router-link>
                  </v-btn>
                </template>
                <span>Control</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="getAccess.role === 'admin'"
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      small
                      @click="
                        editStations(item);
                        create = false;
                        edit = true;
                      "
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small class="mx-2" @click="editProb(item)" icon
                      >mdi-wrench</v-icon
                    >
                  </v-btn>
                </template>
                <span>Maintenance</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="getAccess.role === 'admin'"
                    class="ml-2"
                    fab
                    icon
                    height="20px"
                    width="20px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small @click="deleteStations(item)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.powerMeter.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <router-link
                      :to="{ name: 'Chart', params: { data: item } }"
                      style="color: inherit; text-decoration: none"
                    >
                      <v-icon small>mdi-chart-bell-curve</v-icon>
                    </router-link>
                  </v-btn>
                </template>
                <span>Chart</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </draggable>
      <v-row
        justify="start"
        v-if="
          toggle == 0 &&
            (filter == true || search != '' || getAccess.role !== 'admin')
        "
      >
        <v-col
          v-for="(item, index) in searchStations"
          :key="index"
          xs="12"
          sm="12"
          md="4"
          lg="4"
        >
          <v-card color="white">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        size="25"
                        :color="item.online ? 'green' : 'red'"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <router-link
                          :to="{ name: 'Controls', params: { data: item } }"
                          style="color: inherit; text-decoration: none"
                        >
                          <!-- <v-icon large color="white">mdi-lightbulb-on</v-icon> -->
                        </router-link>
                      </v-avatar> </template
                    ><span>{{ item.online ? "Online" : "Offline" }}</span>
                  </v-tooltip>

                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                  MAC Address : {{ item.macAddress }} <br />
                  TimePLC : {{ item.timePLC }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-col align="end" class="mt-0 py-0">
              <v-btn x-small rounded @click="openDetail(item)"
                >More Detail</v-btn
              >
            </v-col>
            <v-card-actions justify="center" align="center" class="pt-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <router-link
                      :to="{ name: 'Controls', params: { data: item } }"
                      style="color: inherit; text-decoration: none"
                    >
                      <v-icon small>mdi-lightbulb-on</v-icon>
                    </router-link>
                  </v-btn>
                </template>
                <span>Control</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="getAccess.role === 'admin'"
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      small
                      @click="
                        editStations(item);
                        create = false;
                        edit = true;
                      "
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small class="mx-2" @click="editProb(item)" icon
                      >mdi-wrench</v-icon
                    >
                  </v-btn>
                </template>
                <span>Maintenance</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="getAccess.role === 'admin'"
                    class="ml-2"
                    fab
                    icon
                    height="20px"
                    width="20px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small @click="deleteStations(item)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.powerMeter.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <router-link
                      :to="{ name: 'Chart', params: { data: item } }"
                      style="color: inherit; text-decoration: none"
                    >
                      <v-icon small>mdi-chart-bell-curve</v-icon>
                    </router-link>
                  </v-btn>
                </template>
                <span>Chart</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="toggle != 0">
        <v-col cols="12" class="mt-n2">
          <v-card>
            <v-data-table
              :headers="headers"
              :items="stations"
              :items-per-page="5"
              :search="search"
              class="ma-2"
              :options.sync="options"
              :hide-default-header="isMobile"
            >
              <template v-slot:header.phase>
                <v-row justify="center" align="center">
                  <v-col cols="3"></v-col>
                  <v-col cols="3">Phase1</v-col>
                  <v-col cols="3">Phase2</v-col>
                  <v-col cols="3">Phase3</v-col>
                </v-row>
              </template>
              <template
                v-slot:body="props"
                v-if="
                  filter == false &&
                    search == '' &&
                    getAccess.role === 'admin' &&
                    !isMobile
                "
              >
                <draggable
                  :list="props.items"
                  tag="tbody"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="checkMoveTable(props)"
                  v-if="!isMobile"
                >
                  <tr
                    v-for="(item, index) in props.items"
                    :key="index"
                  >
                    <td class="handle" :style="showBorder(item.online)">{{ item.name }}</td>
                    <td v-if="item.company != undefined">
                      {{ item.company.name }}
                    </td>
                    <td v-if="item.company == undefined"></td>
                    <td>{{ item.timePLC }}</td>
                    <td justify="center" align="center" class="mx-0 px-0">
                      <v-row
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length > 0"
                      >
                        <v-col cols="3" justify="center" align="center"
                          ><v-select
                            color="red"
                            hide-details="true"
                            dense
                            outlined
                            v-model="item.show"
                            :items="itemsElect"
                            item-text="text"
                            item-value="value"
                            style="width: 100px; display: inline-flex font-size: 11px;"
                          ></v-select
                        ></v-col>
                        <v-col cols="3">
                          {{ item.powerMeter[item.show].value[0] }}
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="3">
                          {{ item.powerMeter[item.show].value[1] }}
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="3">
                          {{ item.powerMeter[item.show].value[2] }}
                          {{ unit(item.show) }}</v-col
                        >
                      </v-row>
                      <v-row
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length == 0"
                      >
                        <v-col cols="3" justify="center" align="center"
                          ><v-select
                            color="red"
                            hide-details="true"
                            dense
                            outlined
                            v-model="item.show"
                            :items="itemsElect"
                            item-text="text"
                            item-value="value"
                            style="width: 100px; display: inline-flex font-size: 11px;"
                          ></v-select
                        ></v-col>
                        <v-col cols="3">
                          0.00
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="3">
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="3">
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                      </v-row>
                    </td>
                    <td justify="center" align="start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <router-link
                              :to="{ name: 'Controls', params: { data: item } }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-lightbulb-on</v-icon>
                            </router-link>
                          </v-btn>
                        </template>
                        <span>Control</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              @click="
                                editStations(item);
                                create = false;
                                edit = true;
                              "
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mx-2" @click="editProb(item)" icon
                              >mdi-wrench</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Maintenance</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon @click="deleteStations(item)"
                              >mdi-trash-can-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.powerMeter.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <router-link
                              :to="{ name: 'Chart', params: { data: item } }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-chart-bell-curve</v-icon>
                            </router-link>
                          </v-btn>
                        </template>
                        <span>Chart</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </draggable>

                <draggable
                  :list="props.items"
                  tag="tbody"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="checkMoveTable(props)"
                  v-else
                >
                  <tr
                    v-for="(item, index) in props.items"
                    :key="index"
                    class="handle"
                  >
                    <td :style="showBorder(item.online)" class="col-12">
                      <v-row>
                        <v-col><b>Station</b></v-col
                        ><v-spacer></v-spacer>
                        <v-col> {{ item.name }} </v-col>
                      </v-row>
                      <div class="row">
                        <v-col><b>Company</b></v-col
                        ><v-spacer></v-spacer>
                        <v-col v-if="item.company != undefined">
                          {{ item.company.name }}
                        </v-col>
                        <v-col v-if="item.company == undefined"> </v-col>
                      </div>
                      <div class="row">
                        <v-col><b>Time PLC</b></v-col
                        ><v-spacer></v-spacer>
                        <v-col> {{ item.timePLC }} </v-col>
                      </div>
                      <v-row align="center">
                        <v-col cols="12">
                          <v-select
                            color="red"
                            hide-details="true"
                            dense
                            outlined
                            v-model="item.show"
                            :items="itemsElect"
                            item-text="text"
                            item-value="value"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <div
                        class="row"
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length > 0"
                      >
                        <v-col cols="4">
                          Phase 1 <br />
                          {{ item.powerMeter[item.show].value[0] }}
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="4">
                          Phase 2 <br />
                          {{ item.powerMeter[item.show].value[1] }}
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="4">
                          Phase 3 <br />
                          {{ item.powerMeter[item.show].value[2] }}
                          {{ unit(item.show) }}</v-col
                        >
                      </div>
                      <div
                        class="row"
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length == 0"
                      >
                        <v-col cols="4">
                          Phase 1 <br />
                          0.00
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="4">
                          Phase 2 <br />
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="4">
                          Phase 3 <br />
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                      </div>
                      <div class="row" justify="center" align="start">
                        <v-col justify="center" align-self="center" cols="3"
                          ><b>Action</b></v-col
                        >
                        <v-col align-self="center">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <router-link
                              :to="{
                                name: 'Controls',
                                params: { data: item },
                              }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-lightbulb-on</v-icon>
                            </router-link>
                          </v-btn>

                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <v-icon
                              @click="
                                editStations(item);
                                create = false;
                                edit = true;
                              "
                              >mdi-pencil</v-icon
                            >
                          </v-btn>

                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <v-icon class="mx-2" @click="editProb(item)" icon
                              >mdi-wrench</v-icon
                            >
                          </v-btn>

                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <v-icon @click="deleteStations(item)"
                              >mdi-trash-can-outline</v-icon
                            >
                          </v-btn>

                          <v-btn
                            v-if="item.powerMeter.length > 0"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <router-link
                              :to="{ name: 'Chart', params: { data: item } }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-chart-bell-curve</v-icon>
                            </router-link>
                          </v-btn>
                        </v-col>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </template>
              <template v-slot:body="props" v-else>
                <tbody v-if="!isMobile">
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td :style="showBorder(item.online)">{{ item.name }}</td>
                    <td v-if="item.company != undefined">
                      {{ item.company.name }}
                    </td>
                    <td v-if="item.company == undefined"></td>
                    <td>{{ item.timePLC }}</td>
                    <td justify="center" align="center" class="mx-0 px-0">
                      <v-row
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length > 0"
                      >
                        <v-col cols="3" justify="center" align="center"
                          ><v-select
                            color="red"
                            hide-details="true"
                            dense
                            outlined
                            v-model="item.show"
                            :items="itemsElect"
                            item-text="text"
                            item-value="value"
                            style="width: 100px; display: inline-flex font-size: 11px;"
                          ></v-select
                        ></v-col>
                        <v-col cols="3">
                          {{ item.powerMeter[item.show].value[0] }}
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="3">
                          {{ item.powerMeter[item.show].value[1] }}
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="3">
                          {{ item.powerMeter[item.show].value[2] }}
                          {{ unit(item.show) }}</v-col
                        >
                      </v-row>
                      <v-row
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length == 0"
                      >
                        <v-col cols="3" justify="center" align="center"
                          ><v-select
                            color="red"
                            hide-details="true"
                            dense
                            outlined
                            v-model="item.show"
                            :items="itemsElect"
                            item-text="text"
                            item-value="value"
                            style="width: 100px; display: inline-flex font-size: 11px;"
                          ></v-select
                        ></v-col>
                        <v-col cols="3">
                          0.00
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="3">
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="3">
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                      </v-row>
                    </td>
                    <td justify="center" align="start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <router-link
                              :to="{ name: 'Controls', params: { data: item } }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-lightbulb-on</v-icon>
                            </router-link>
                          </v-btn>
                        </template>
                        <span>Control</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              @click="
                                editStations(item);
                                create = false;
                                edit = true;
                              "
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="mx-2" @click="editProb(item)" icon
                              >mdi-wrench</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Maintenance</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon @click="deleteStations(item)"
                              >mdi-trash-can-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.powerMeter.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <router-link
                              :to="{ name: 'Chart', params: { data: item } }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-chart-bell-curve</v-icon>
                            </router-link>
                          </v-btn>
                        </template>
                        <span>Chart</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td :style="showBorder(item.online)" class="col-12">
                      <v-row>
                        <v-col><b>Station</b></v-col
                        ><v-spacer></v-spacer>
                        <v-col> {{ item.name }} </v-col>
                      </v-row>
                      <div class="row">
                        <v-col><b>Company</b></v-col
                        ><v-spacer></v-spacer>
                        <v-col v-if="item.company != undefined">
                          {{ item.company.name }}
                        </v-col>
                        <v-col v-if="item.company == undefined"> </v-col>
                      </div>
                      <div class="row">
                        <v-col><b>Time PLC</b></v-col
                        ><v-spacer></v-spacer>
                        <v-col> {{ item.timePLC }} </v-col>
                      </div>
                      <v-row align="center">
                        <v-col cols="12">
                          <v-select
                            color="red"
                            hide-details="true"
                            dense
                            outlined
                            v-model="item.show"
                            :items="itemsElect"
                            item-text="text"
                            item-value="value"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <div
                        class="row"
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length > 0"
                      >
                        <v-col cols="4">
                          Phase 1 <br />
                          {{ item.powerMeter[item.show].value[0] }}
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="4">
                          Phase 2 <br />
                          {{ item.powerMeter[item.show].value[1] }}
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="4">
                          Phase 3 <br />
                          {{ item.powerMeter[item.show].value[2] }}
                          {{ unit(item.show) }}</v-col
                        >
                      </div>
                      <div
                        class="row"
                        justify="center"
                        align="center"
                        v-if="item.powerMeter.length == 0"
                      >
                        <v-col cols="4">
                          Phase 1 <br />
                          0.00
                          {{ unit(item.show) }}
                        </v-col>
                        <v-col cols="4">
                          Phase 2 <br />
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                        <v-col cols="4">
                          Phase 3 <br />
                          0.00
                          {{ unit(item.show) }}</v-col
                        >
                      </div>
                      <div class="row" justify="center" align="start">
                        <v-col justify="center" align-self="center" cols="3"
                          ><b>Action</b></v-col
                        >
                        <v-col align-self="center">
                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <router-link
                              :to="{
                                name: 'Controls',
                                params: { data: item },
                              }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-lightbulb-on</v-icon>
                            </router-link>
                          </v-btn>

                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <v-icon
                              @click="
                                editStations(item);
                                create = false;
                                edit = true;
                              "
                              >mdi-pencil</v-icon
                            >
                          </v-btn>

                          <v-btn
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <v-icon class="mx-2" @click="editProb(item)" icon
                              >mdi-wrench</v-icon
                            >
                          </v-btn>

                          <v-btn
                            v-if="getAccess.role === 'admin'"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <v-icon @click="deleteStations(item)"
                              >mdi-trash-can-outline</v-icon
                            >
                          </v-btn>

                          <v-btn
                            v-if="item.powerMeter.length > 0"
                            class="ml-2 mt-3"
                            fab
                            icon
                            height="40px"
                            width="40px"
                            color="red"
                          >
                            <router-link
                              :to="{ name: 'Chart', params: { data: item } }"
                              style="color: inherit; text-decoration: none"
                            >
                              <v-icon>mdi-chart-bell-curve</v-icon>
                            </router-link>
                          </v-btn>
                        </v-col>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        v-if="getAccess.role === 'admin'"
        :style="isMobile ? 'bottom: 50px;' : 'bottom: -20px;'"
        class="mx-2 my-10"
        fixed
        fab
        color="red"
        dark
        absolute
        bottom
        right
        @click="
          close();
          opendialog();
          create = true;
          edit = false;
        "
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div id="modal">
        <v-dialog v-model="dialog_add" width="500" persistent>
          <v-card>
            <v-card-title
              v-if="create"
              class="headline red darken-2 white--text"
            >
              Create Station
            </v-card-title>
            <v-card-title v-if="edit" class="headline red darken-2 white--text">
              Edit Station
            </v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="savePost"
                ref="postData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  name="name"
                  id="id"
                  label="Name"
                  hide-details="auto"
                  v-model="postData.name"
                  :rules="[(v) => !!v || 'Name is required']"
                  required
                ></v-text-field>
                <v-text-field
                  name="macaddress"
                  id="id"
                  label="MAC Address"
                  hide-details="auto"
                  v-model="postData.macAddress"
                  :rules="[(v) => !!v || 'MAC Address is required']"
                  required
                ></v-text-field>
                <v-select
                  :items="provinces"
                  v-model="postData.province"
                  label="Province"
                  :rules="[(v) => !!v || 'Province is required']"
                  required
                ></v-select>
                <v-select
                  :items="types"
                  item-text="name"
                  item-value="value"
                  v-model="postData.type"
                  label="PLC Type"
                  :disabled="!create"
                  :rules="[(v) => !!v || 'PLC Type is required']"
                  required
                ></v-select>
                <v-select
                  :items="getCompanies"
                  item-text="name"
                  item-value="id"
                  v-model="postData.company"
                  label="Company"
                  @change="changeCompany"
                  :rules="[(v) => !!v || 'Company is required']"
                  required
                ></v-select>
                <v-select
                  v-if="departmetEnable"
                  :items="getCompanyWithDepartment"
                  item-text="name"
                  item-value="id"
                  v-model="postData.department"
                  label="Department"
                ></v-select>
                <v-checkbox
                  label="16 output extension card"
                  v-model="postData.extension"
                  :disabled="!create"
                ></v-checkbox>
                <v-checkbox
                  label="Power Meter"
                  v-model="postData.power"
                ></v-checkbox>
                <v-textarea
                  outlined
                  id="id"
                  label="Description"
                  name="description"
                  hide-details="auto"
                  v-model="postData.description"
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="success"
                v-if="create"
                text
                @click="createStations()"
                >Save</v-btn
              >
              <v-btn color="success" v-if="edit" text @click="updateStations()"
                >Update</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_prob" width="500" persistent>
          <v-card>
            <v-card-title class="headline red darken-2 white--text">
              Maintenance Report
            </v-card-title>
            <v-card-text>
              <v-form
                ref="problem"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <br />
                <div
                  class="grey lighten-2"
                  style="
                    padding-left: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                  "
                >
                  Station : {{ postData.name }} <br />
                  Name : {{ getAccess.name }} <br />
                </div>
                <br />
                <v-textarea
                  outlined
                  label="Problem"
                  name="problem"
                  v-model="problem.prob"
                  :rules="[(v) => !!v || 'Problem is required']"
                  required
                ></v-textarea>
                <v-textarea
                  outlined
                  label="How To Solve"
                  name="solve"
                  v-model="problem.solve"
                  :rules="[(v) => !!v || 'Solve is required']"
                  required
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="success" text @click="saveProb">Save</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close_prob"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_detail" width="500" presistent>
          <v-card color="white" v-if="detail.powerMeter">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline"
                  v-text="detail.name"
                ></v-card-title>
                <v-card-subtitle
                  >MAC Address : {{ detail.macAddress }} <br />
                  TimePLC : {{ detail.timePLC }}
                </v-card-subtitle>
                <v-card-text style="overflow-y: auto; height: 40px">
                  <div class="body-1">{{ detail.description }}</div>
                </v-card-text>
              </div>
              <v-avatar
                class="mr-4 mt-4"
                size="60"
                :color="detail.online ? 'green' : 'red'"
              >
                <router-link
                  :to="{ name: 'Controls', params: { data: detail } }"
                  style="color: inherit; text-decoration: none"
                >
                  <p class="mt-3 white--text">
                    {{ detail.online ? "Online" : "Offline" }}
                  </p>
                </router-link>
              </v-avatar>
            </div>
            <v-col class="ma-0" cols="12" align="center">
              <v-btn-toggle v-model="detail.show" dense color="red">
                <v-btn class="text-upper" small> Voltage </v-btn>
                <v-btn class="text-upper" small> Current </v-btn>
                <v-btn class="text-upper" small> Power </v-btn>
              </v-btn-toggle> </v-col
            ><v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <v-row class="ma-0 pa-0" align="center">
                  <v-col class="ma-0 pa-0" align="center" style="font-size: 80%"
                    >Phase 1</v-col
                  >
                  <v-col class="ma-0 pa-0" align="center" style="font-size: 80%"
                    >Phase 2</v-col
                  >
                  <v-col class="ma-0 pa-0" align="center" style="font-size: 80%"
                    >Phase 3</v-col
                  >
                </v-row>
                <v-row
                  class="ma-0 pa-0"
                  justify="center"
                  v-if="detail.powerMeter.length > 0"
                >
                  <v-col class="ma-0 pa-0" align="center">
                    {{ detail.powerMeter[detail.show].value[0] }}
                    {{ unit(detail.show) }}
                  </v-col>
                  <v-col class="ma-0 pa-0" align="center">
                    {{ detail.powerMeter[detail.show].value[1] }}
                    {{ unit(detail.show) }}</v-col
                  >
                  <v-col class="ma-0 pa-0" align="center">
                    {{ detail.powerMeter[detail.show].value[2] }}
                    {{ unit(detail.show) }}
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 pa-0"
                  justify="center"
                  v-if="detail.powerMeter.length == 0"
                >
                  <v-col class="ma-0 pa-0" align="center">
                    0.00
                    {{ unit(detail.show) }}
                  </v-col>
                  <v-col class="ma-0 pa-0" align="center">
                    0.00
                    {{ unit(detail.show) }}</v-col
                  >
                  <v-col class="ma-0 pa-0" align="center">
                    0.00
                    {{ unit(detail.show) }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mb-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <router-link
                      :to="{ name: 'Controls', params: { data: detail } }"
                      style="color: inherit; text-decoration: none"
                    >
                      <v-icon>mdi-lightbulb-on</v-icon>
                    </router-link>
                  </v-btn>
                </template>
                <span>Control</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="getAccess.role === 'admin'"
                    class="ml-2 mb-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      @click="
                        editStations(detail);
                        create = false;
                        edit = true;
                      "
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mb-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-2" @click="editProb(detail)" icon
                      >mdi-wrench</v-icon
                    >
                  </v-btn>
                </template>
                <span>Maintenance</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="getAccess.role === 'admin'"
                    class="ml-2 mb-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon @click="deleteStations(detail)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-tooltip bottom v-if="detail.powerMeter.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mb-2"
                    fab
                    icon
                    height="40px"
                    width="40px"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <router-link
                      :to="{ name: 'Chart', params: { data: detail } }"
                      style="color: inherit; text-decoration: none"
                    >
                      <v-icon>mdi-chart-bell-curve</v-icon>
                    </router-link>
                  </v-btn>
                </template>
                <span>Chart</span>
              </v-tooltip>
            </v-card-actions>
            <v-col justify="center" align="center">
              <v-btn @click="dialog_detail = false">
                Close
              </v-btn>
            </v-col>
          </v-card>
        </v-dialog>
      </div>
      <v-snackbar v-model="snackbar" buttom left :color="color">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
      <infinite-loading
        @infinite="infiniteHandler"
        v-if="this.load && this.toggle == 0"
      ></infinite-loading>
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import authHeader from "../services/auth-header";
import draggable from "vuedraggable";
import provinces from "@/config/provinces";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    draggable,
    InfiniteLoading,
  },
  computed: {
    searchStations: function() {
      return this.stations.filter((station) => {
        return (
          station.name.match(this.search) ||
          station.macAddress.match(this.search)
        );
      });
    },
    provinceSelect() {
      let newFirstElement = "All";
      let array = provinces;
      let newArray = [newFirstElement].concat(array);
      return newArray;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getStations() {
      return this.$store.getters.stations;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getCompanies() {
      return this.$store.getters.allCompanies;
    },
    getSearchCompanies() {
      let newFirstElement = { name: "All", id: null };
      let array = this.$store.getters.allCompanies;
      let newArray = [newFirstElement].concat(array);
      return newArray;
    },
    getCompanyWithDepartment() {
      return this.$store.getters.getCompanyWithDepartment;
    },
    dragOptions() {
      return {
        animation: 500,
        group: "description",
        disabled: false,
      };
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        if (this.filter == false) {
          this.checkNumTable();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    this.loadStations();
    this.$options.sockets.status = (data) => {
      const obj = JSON.parse(data);
      //console.log(obj);
      if (this.stations.length > 0) {
        for (const i in obj) {
          const index = this.stations.findIndex(
            (s) => s.macAddress === obj[i].macAddress
          );
          if (index > -1) {
            this.stations[index].online = obj[i].online;
          }
        }
      }
    };
    this.$options.sockets.voltage = (data) => {
      //console.log(data);
      let value = data.data.split("/");
      value.splice(3, 1);
      const valueNum = value.map((num) => Number.parseFloat(num).toFixed(2));
      const index = this.stations.findIndex(
        (s) => s.macAddress === data.macAddress
      );
      //console.log(index);
      if (index > -1) {
        if (this.stations[index].powerMeter.length == 3) {
          this.stations[index].powerMeter[0].value = valueNum;
        }
      }
    };
    this.$options.sockets.current = (data) => {
      //console.log(data);
      let value = data.data.split("/");
      //console.log(value);
      const index = this.stations.findIndex(
        (s) => s.macAddress === data.macAddress
      );
      //console.log(index);
      if (index > -1) {
        if (this.stations[index].powerMeter.length == 3) {
          this.stations[index].powerMeter[1].value = value;
        }
      }
    };
    this.$options.sockets.power = (data) => {
      //console.log(data);
      let value = data.data.split("/");
      value.splice(3, 1);
      const valueNum = value.map((num) => Number.parseFloat(num).toFixed(2));
      const index = this.stations.findIndex(
        (s) => s.macAddress === data.macAddress
      );
      //console.log(index);
      if (index > -1) {
        if (this.stations[index].powerMeter.length == 3) {
          this.stations[index].powerMeter[2].value = valueNum;
        }
      }
    };
    this.$options.sockets.timePLC = (data) => {
      //console.log(data);
      const index = this.stations.findIndex(
        (s) => s.macAddress === data.macAddress
      );
      //console.log(this.stations[index]);
      if (index > -1) {
        this.stations[index].timePLC = data.time;
      }
    };
  },
  data() {
    return {
      //isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      detail: {},

      dialog_detail: false,
      isMobile: false,
      options: {},
      search: "",
      allStations: 0,
      load: true,
      data: {
        last: 0,
        length: 0,
      },
      valid: true,
      toggle: 0,
      toggleCard: 0,
      drag: false,
      filter: false,
      user: "user001",
      postData: {},
      problem: {},
      stations: [],
      controlData: [],
      dialog_add: false,
      dialog_prob: false,
      create: false,
      edit: false,
      dashboard: true,
      dashboard_set: false,
      fab: false,
      snackbar: false,
      text: "",
      color: "",
      filterData: {
        province: "All",
        company: null,
      },
      types: [
        { name: "CPU-1212C (6 Output)", value: 6 },
        { name: "CPU-1214C (10 Output)", value: 10 },
      ],
      headers: [
        {
          text: "Station",
          align: "start",
          sortable: false,
          value: "name",
          width: "10%",
        },
        {
          text: "Company",
          align: "start",
          sortable: false,
          value: "name",
          width: "10%",
        },
        { text: "Time PLC", value: "timePLC", sortable: false, width: "10%" },
        {
          text: "Phase",
          value: "phase",
          sortable: false,
          align: "center",
          width: "40%",
        },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          align: "center",
          width: "40%",
        },
      ],
      selectElect: 0,
      isConnected: false,
      socketMessage: "",
      departmetEnable: false,
      department: "",
      provinces: provinces,
      itemsElect: [
        { text: "Voltage", value: 0 },
        { text: "Current", value: 1 },
        { text: "Power", value: 2 },
      ],
    };
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
      console.log(data);
    },
  },
  methods: {
    openDetail(data) {
      this.dialog_detail = true;
      this.detail = data;
      console.log(this.detail);
    },
    onResize() {
      if (window.innerWidth < 960) this.isMobile = true;
      else this.isMobile = false;
    },
    unit(data) {
      //console.log(data);
      switch (data) {
        case 0:
          return "(V)";
        case 1:
          return "(A)";
        case 2:
          return "(W)";
      }
    },
    checkNumTable: async function() {
      const { page, itemsPerPage } = this.options;
      console.log(page);
      let dataShow = page * itemsPerPage;
      if (this.stations.length - dataShow < itemsPerPage) {
        let data = {
          last: this.stations.length,
          length: itemsPerPage,
        };
        this.$store.dispatch("infiniteScroll", data).then((res) => {
          if (res.length === 0) {
            this.load = false;
          }
          setTimeout(() => {
            for (let i = 0; i < res.length; i++) {
              this.stations.push(res[i]);
            }
          }, 100);
        });
      }
    },
    checkMoveTable: function(evt) {
      console.log(evt);
      let data = {
        station: null,
        page: null,
        itemsPerPage: null,
        type: "table",
      };
      this.drag = false;
      data.station = evt.items;
      data.itemsPerPage = evt.options.itemsPerPage;
      data.page = evt.options.page;
      this.$store.dispatch("updateOrder", data).then(
        () => {
          let data = {
            last: 0,
            length: this.stations.length,
          };
          this.$store.dispatch("infiniteScroll", data).then((res) => {
            this.stations = res;
          });
          this.color = "success";
          this.text = "Update has been successfully";
          this.snackbar = true;
        },
        (error) => {
          this.text = error.message;
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    checkMove: function(evt) {
      this.drag = false;
      let data = {
        station: null,
        length: null,
        type: "dashboard",
      };
      if (evt.oldIndex != evt.newIndex) {
        data.station = this.stations;
        data.length = this.stations.length;
        this.$store.dispatch("updateOrder", data).then(
          () => {
            this.color = "success";
            this.text = "Update has been successfully";
            this.snackbar = true;
          },
          (error) => {
            this.text = error.message;
            this.color = "red";
            this.snackbar = true;
          }
        );
      }
    },
    infiniteHandler($state) {
      if (this.toggle == 0) {
        if (this.data.length === 0) {
          this.data.length = this.cheackScreenInitial();
        } else {
          this.data.length = this.cheackScreenNext();
        }
        this.data.last = this.stations.length;
        //console.log(this.data);
        this.$store.dispatch("infiniteScroll", this.data).then((res) => {
          //console.log(res);
          if (res.status != undefined) {
            if (res.status == 500) {
              this.load = false;
            }
          }
          if (res.length === 0) {
            this.load = false;
          }
          setTimeout(() => {
            for (let i = 0; i < res.length; i++) {
              this.stations.push(res[i]);
            }
            $state.loaded();
          }, 100);
        });
      }
    },
    cheackScreenInitial() {
      if (window.matchMedia("screen and (max-width:500px)").matches) {
        return 4;
      } else if (window.matchMedia("screen and (max-width:1400px)").matches) {
        return 4;
      } else {
        return 12;
      }
    },
    cheackScreenNext() {
      if (window.matchMedia("screen and (max-width:960px)").matches) {
        return 4;
      } else if (window.matchMedia("screen and (max-width:1400px)").matches) {
        return 4;
      } else {
        return 4;
      }
    },
    searchDate() {
      if (
        this.filterData.province == "All" &&
        this.filterData.company == null
      ) {
        this.filter = false;
      } else {
        this.filter = true;
      }
      this.load = false;
      this.$store.dispatch("filterStations", this.filterData).then(
        (res) => {
          console.log(res);
          this.stations = res;
          this.color = "success";
          this.text = "Searching has been successfully";
          this.snackbar = true;
        },
        (error) => {
          this.text = error.message;
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    opendialog() {
      this.dialog_add = true;
    },
    loadStations() {
      this.$store.dispatch("getStations").then((res) => {
        this.allStations = res;
      });
      this.$store.dispatch("getCompanies");
      // console.log(this.stations);
    },
    close() {
      this.dialog_add = false;
      setTimeout(() => {
        this.postData = Object.assign({}, this.default);
        this.$refs.postData.resetValidation();
        this.editedIndex = -1;
        this.departmetEnable = false;
      }, 300);
    },
    close_prob() {
      this.dialog_prob = false;
      setTimeout(() => {
        this.problem = Object.assign({}, this.default);
        this.$refs.problem.resetValidation();
        this.editedProbIndex = -1;
      }, 300);
    },
    createStations() {
      if (this.$refs.postData.validate()) {
        let stationData = {
          name: this.postData.name,
          description: this.postData.description,
          macAddress: this.postData.macAddress,
          type: this.postData.type,
          extension: this.postData.extension || false,
          power: this.postData.power || false,
          company: this.postData.company,
          department: this.postData.department,
          province: this.postData.province,
          last: this.allStations.length,
        };
        this.$store.dispatch("createStations", stationData).then(
          (res) => {
            this.dialog_add = false;
            //this.stations = this.getStations;
            //console.log(this.data);
            this.color = "success";
            this.text = "Post has been successfully saved.";
            this.snackbar = true;
            let data = {
              last: 0,
              length: this.stations.length + 1,
            };
            this.$store.dispatch("infiniteScroll", data).then((res) => {
              console.log(res);
              this.stations = res;
            });
            //console.log(response);
          },
          (error) => {
            //console.log(error);
            // console.log(error.response)
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
        );
      }
    },
    editStations(item) {
      console.log(item);
      let id;
      if (item.company != undefined) {
        id = item.company.id;
        this.$store.dispatch("getCompanyWithDepartment", id).then(() => {
          //this.getCompanyWithDepartment.shift();
        });
      }
      this.editedIndex = this.stations.indexOf(item);
      console.log(this.editedIndex);
      this.postData = Object.assign({}, item);
      console.log(this.postData);
      this.dialog_add = true;
      this.departmetEnable = true;
    },
    editProb(item) {
      this.editedProbIndex = this.stations.indexOf(item);
      this.postData = Object.assign({}, item);
      console.log(this.postData);
      this.dialog_prob = true;
    },
    saveProb() {
      if (this.$refs.problem.validate()) {
        this.problem.user = this.getAccess.name;
        this.problem.stationID = this.postData._id;
        console.log(this.problem);
        let apiURL = process.env.VUE_APP_API_URL + "maintenance/";
        axios
          .post(
            apiURL,
            {
              id: this.problem.stationID,
              problem: this.problem.prob,
              solving: this.problem.solve,
            },
            { headers: authHeader() }
          )
          .then(() => {
            this.postData = {};
            this.close_prob();
            this.loadStations();
            this.color = "success";
            this.text = "Problem has been successfully saved.";
            this.snackbar = true;
          })
          .catch((error) => {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          });
      }
    },
    updateStations() {
      let powerMeter = [
        {
          name: "voltage",
          value: [0, 0, 0],
          high: 0,
          low: 0,
          timeLog: 0,
        },
        {
          name: "current",
          value: [0, 0, 0],
          high: 0,
          low: 0,
          timeLog: 0,
        },
        {
          name: "power",
          value: [0, 0, 0],
          high: 0,
          low: 0,
          timeLog: 0,
        },
      ];
      if (this.postData.power == true) {
        this.postData.powerMeter = powerMeter;
      } else {
        this.postData.powerMeter = [];
      }
      this.$store.dispatch("updateStations", this.postData).then(
        (station) => {
          this.close();
          //this.loadStations();
          //this.stations = this.getStations;
          this.stations.splice(this.editedIndex, 1, station);
          //console.log(this.postData);
          //this.stations[this.editedIndex] = this.postData;

          //console.log(this.stations);

          this.color = "info";
          this.text = "Post has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    deleteStations(item) {
      //console.log(id);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            // let apiURL = `http://localhost:3000/api/station/${id}`;
            // console.log(apiURL);
            // axios.delete(apiURL).then(() => {
            //   this.loadStations();
            // });
            //console.log(item);
            this.$store.dispatch("deleteStations", item).then(() => {
              this.$swal("Deleted!", "Your file has been deleted.", "success");
              //console.log(this.stations);
              const index = this.stations.findIndex((s) => s._id === item._id);
              //console.log(index);
              this.stations.splice(index, 1);
              //console.log(this.stations);
            });
          } catch (error) {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
          //this.$swal("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    changeCompany(id) {
      this.$store.dispatch("getCompanyWithDepartment", id).then(
        () => {
          //this.department =this.getCompanyWithDepartment;
          //this.getCompanyWithDepartment.shift();
          //console.log(this.getCompanyWithDepartment)
          this.departmetEnable = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    showBorder(data) {
      if (data == 0) {
        return {
          borderLeft: "8px solid",
          borderLeftColor: "#F44336",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        };
      } else {
        return {
          borderLeft: "8px solid",
          borderLeftColor: "#4CAF50",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        };
      }
    },
  },
};
</script>
<style>
.v-select__selection {
  font-size: 12px;
}
.handle {
  cursor: move;
}
#tr {
  cursor: move;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
