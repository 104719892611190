<template>
  <div :id="chartId" class="fill"></div>
</template>

<script>
const lcjs = require("@arction/lcjs");
const { lightningChart, AxisTickStrategies, Themes } = lcjs;

export default {
  name: "ChartData",
  props: ["data"],
  data() {
    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
    this.chart = null;
    return {
      chartId: null,
    };
  },
  methods: {
    createChart() {
      // Create chartXY
      //console.log(data");
      this.chart = lightningChart().ChartXY({
        theme: Themes.lightNature,
        container: `${this.chartId}`,
      });
      this.chart
        .getDefaultAxisX()
        .setTickStrategy(AxisTickStrategies.DateTime)
        .setInterval({
          start: this.data.time.start,
          end: this.data.time.end,
        });
      //this.chart = lightningChart().ChartXY({  });
      // Set chart title
      this.chart
        .getDefaultAxisY()
        .setTitle(this.data.title)
        
      this.chart
        .setPadding({
          right: 50,
        })
        .setTitle(this.data.chartTitle);
      // Add line series to the chart
      const lineSeries1 = this.chart
        .addLineSeries()
        .setName(this.data.series[0].name);
      const lineSeries2 = this.chart
        .addLineSeries()
        .setName(this.data.series[1].name);
      const lineSeries3 = this.chart
        .addLineSeries()
        .setName(this.data.series[2].name);
      // Set stroke style of the line
      //lineSeries.setStrokeStyle((style) => style.setThickness(5));
      // Add data data to the line series
      lineSeries1.add(this.data.series[0].data);
      lineSeries2.add(this.data.series[1].data);
      lineSeries3.add(this.data.series[2].data);

      const legend = this.chart
        .addLegendBox()
        // Dispose example UI elements automatically if they take too much space. This is to avoid bad UI on mobile / etc. devices.
        .setAutoDispose({
          type: "max-width",
          maxWidth: 0.3,
        });
      legend.add(this.chart);
    },
  },
  beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000);
  },
  mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    this.createChart();
  },
  beforeUnmount() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    this.chart.dispose();
  },
};
</script>
<style scoped>
.fill {
  height: 100%;
}
</style>
