<template>
  <v-app id="login" class="secondary" style="background: #f5f5f5">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img
                    :src="image"
                    alt="Vue Material Admin"
                    width="180"
                    height="180"
                  />
                  <h1 class="flex my-4 red--text text--darken-1">
                    LED Billboard IOT
                  </h1>
                </div>
                <v-form>
                  <v-text-field
                    append-icon="mdi-account"
                    name="username"
                    label="Username"
                    type="text"
                    v-model="user.username"
                    :error="error"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                    "
                    name="password"
                    label="Password"
                    id="password"
                    :rules="[rules.required]"
                    v-model="user.password"
                    :error="error"
                    @click:append="hidePassword = !hidePassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="white--text"
                  block
                  color="red darken-2"
                  @click="login"
                  :loading="loading"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" :timeout="2000" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import User from "../models/user";
export default {
  data() {
    return {
      user: new User("", ""),
      loading: false,
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      image: process.env.VUE_APP_SOCKET_URL + "/images/logo.png",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/Home");
    }
  },
  mounted() {
    let self = this;
    window.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        self.login();
      }
    });
  },
  methods: {
    login() {
      const vm = this;

      if (!vm.user.username || !vm.user.password) {
        vm.result = "Username and Password can't be null.";
        vm.showResult = true;

        return;
      } else {
        //console.log(this.user)
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$store.dispatch("auth/decode").then(() => {
              this.clearUser();
              if (this.getAccess.firstLogin) {
                this.$router.push("/resetPassword");
              } else {
                this.$router.push("/Home");
              }
            });
          },
          (error) => {
            // this.loading = false;
            // vm.error = true;
            // vm.result = "Username or Password is incorrect.";
            // vm.showResult = true;
            console.log(error.message);
            this.message = error.message;

            this.loading = false;
            vm.error = true;
            vm.result = this.message;
            vm.showResult = true;
          }
        );
      }
    },
    clearUser() {
      this.user.username = "";
      this.user.password = "";
    },
  },
};
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
