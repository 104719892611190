<template>
  <v-container fluid>
    <v-card class="mx-auto elevation-19">
      <v-card-title>
        <h3 class="blue-grey--text">Companies</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="#2C3A47"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="getCompanies"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="success"
            class="mx-2"
            fab
            x-small
            @click="editCompany(item)"
            outlined
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="pink"
            fab
            x-small
            @click="deleteCompany(item.id)"
            outlined
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-results>
          <v-alert :value="true" color="pink" icon="warning" dark>
            Your Search {{ search }} does not exist.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      class="mx-2 my-10"
      :style="isMobile ? 'bottom: 50px;' : 'bottom: -20px;'"
      fixed
      fab
      color="red"
      dark
      absolute
      bottom
      right
      @click="
        close();
        opendialog();
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div id="modal">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title v-if="create" class="headline red darken-2 white--text">
            Add Company
          </v-card-title>
          <v-card-title v-else class="headline red darken-2 white--text">
            Edit Company
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="saveComany"
              ref="companyData"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                name="name"
                id="id"
                label="Name"
                hide-details="auto"
                v-model="companyData.name"
                :rules="[(v) => !!v || 'Name is required']"
                required
                class="mb-6"
              ></v-text-field>

              <v-list-item two-line>
                <v-list-item-avatar size="120">
                  <img :src="url" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    >Photo size recommended 256x256</v-list-item-title
                  >
                  <v-row>
                    <v-btn
                      color="primary"
                      class="ml-3 mt-1 white--text"
                      :loading="isSelecting"
                      @click="onButtonClickFile"
                    >
                      Upload
                      <v-icon right dark> mdi-cloud-upload </v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      class="ml-2 mt-1 white--text"
                      v-if="isRemove"
                      @click="removeFile()"
                    >
                      Remove
                      <v-icon right dark> mdi-delete </v-icon>
                    </v-btn>
                  </v-row>
                  <input
                    ref="uploader"
                    type="file"
                    class="d-none"
                    @change="handleImage"
                    accept="image/*"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="success" text @click="saveCompany()">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar v-model="snackbar" buttom left :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
//import axios from "axios";
import {
  isIOS,
  isMobile,
  isAndroid,
  isSafari,
  isWinPhone,
} from "mobile-device-detect";
export default {
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getCompanies() {
      return this.$store.getters.allCompanies;
    },
    getCompanyWithDepartment() {
      return this.$store.getters.getCompanyWithDepartment;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    //console.log(this.getUsers);
    this.loadCompany();
  },
  data() {
    return {
      isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      dialog: false,
      create: true,
      mini: false,
      fab: false,
      search: "",
      snackbar: false,
      text: "",
      color: "",
      btnName: "",
      headers: [
        { text: "Name", value: "name", sortable: true, align: "start" },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      companyData: {
        name: "",
        photo: "",
      },
      default: {
        name: "",
        photo: "",
      },
      editedIndex: -1,
      payload: {
        id: "",
        newPassword: "",
        active: true,
      },
      url: "images/nopic.png",
      image: null,
      isSelecting: false,
      isRemove: false,
    };
  },
  methods: {
    opendialog() {
      this.dialog = true;
      this.create = true;
      this.isRemove = false;
    },
    loadCompany() {
      this.$store.dispatch("getCompanies");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.companyData = Object.assign({}, this.default);
        this.editedIndex = -1;
        this.create = true;
        this.$refs.companyData.resetValidation();
        this.url = "images/nopic.png";
      }, 300);
    },
    saveCompany() {
      if (this.editedIndex > -1) {
        this.updateCompany();
      } else {
        this.createCompany();
      }
    },
    createCompany() {
      if (this.$refs.companyData.validate()) {
        this.companyData.photo = this.image;
        this.$store.dispatch("createCompany", this.companyData).then(
          () => {
            this.dialog = false;
            this.loadCompany();
            this.color = "success";
            this.text = "Company has been successfully saved.";
            this.snackbar = true;
          },
          (error) => {
            this.color = "error";
            this.text = error.response.data.error.message;
            this.snackbar = true;
          }
        );
      }
    },
    editCompany(item) {
      this.editedIndex = this.getCompanies.indexOf(item);
      this.companyData = Object.assign({}, item);
      this.url = item.photo;

      if (item.photo.endsWith("images/nopic.png")) {
        this.isRemove = false;
      } else {
        this.isRemove = true;
      }
      this.create = false;
      this.dialog = true;
    },
    updateCompany() {
      //this.companyData.photo = this.image;
      console.log(this.companyData);

      this.$store.dispatch("updateCompanies", this.companyData).then(
        () => {
          this.close();
          this.color = "info";
          this.text = "Company has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    deleteCompany(id) {
      console.log(this.getCompanies);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            this.$store.dispatch("deleteCompanies", id).then(() => {});
          } catch (error) {
            console.log(error);
          }
          this.$swal("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    onButtonClickFile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    removeFile() {
      this.$refs.uploader.value = null;
      this.url = "images/nopic.png";
      this.isRemove = false;
      this.companyData.photo = this.url;
    },
    handleImage(e) {
      if (e.target.files[0]) {
        this.url = URL.createObjectURL(e.target.files[0]);
        this.createBase64Image(e.target.files[0]);
        this.isRemove = true;
      } else {
        this.url = "images/nopic.png";
      }
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.image = e.target.result;
        this.companyData.photo = this.image;
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>
