import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// main.js
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";

import * as io from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import "./registerServiceWorker";

// If you don't need the styles, do not connect
//import "sweetalert2/dist/sweetalert2.min.css";
const socketURL = process.env.VUE_APP_SOCKET_URL;
const options = { path: "/socket.io/" };
//console.log(socketURL);
Vue.use(VueSweetalert2);
Vue.use(VueSocketIO, io(socketURL, options), store);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
