var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluidd":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"md6":""}},[_c('h1',{staticClass:"ml-2 mt-1"},[_vm._v(_vm._s(_vm.station.name))])]),_c('v-flex',{attrs:{"md3":""}},[_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.station.autoString),expression:"station.autoString"}],attrs:{"inset":"","label":((_vm.station.autoString) + " MODE")},on:{"click":function($event){return _vm.setAuto(_vm.auto)}},model:{value:(_vm.auto),callback:function ($$v) {_vm.auto=$$v},expression:"auto"}})],1),_c('v-flex',{attrs:{"md3":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.station.autoString),expression:"station.autoString"}],staticClass:"mt-3",attrs:{"outlined":"","color":"indigo"},on:{"click":_vm.setStopAll}},[_vm._v("STOP ALL")])],1)],1),_c('v-row',{attrs:{"justify":"start"}},_vm._l((_vm.data_outs),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-alert',{attrs:{"outlined":"","color":"red darken-2"}},[_c('v-card-title',{attrs:{"dark":"","color":"red darken-3"}},[(index < _vm.station.type)?[(index < 8)?_c('h3',[_vm._v("CPU DQ a."+_vm._s(index.toString()))]):_c('h3',[_vm._v("CPU DQ b."+_vm._s(index - 8))])]:(_vm.station.type + 8 > index && index >= _vm.station.type)?[_c('h3',[_vm._v("Extension DQ a."+_vm._s(index - _vm.station.type))])]:[_c('h3',[_vm._v("Extension DQ b."+_vm._s(index - (_vm.station.type + 8)))])]],2),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"mt-3 ml-1",attrs:{"large":"","color":(_vm.getStation.status & Math.pow(2, index)) > 0
                        ? 'green'
                        : 'red'}},[_vm._v("mdi-checkbox-blank-circle")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":index.toString()},on:{"change":function($event){return _vm.setName(index)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"startTimedialog",refInFor:true,attrs:{"return-value":item.startTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "startTime", $event)},"update:return-value":function($event){return _vm.$set(item, "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time","prepend-icon":"mdi-clock","readonly":"","clearable":"","disabled":!_vm.auto},on:{"click:clear":function($event){return _vm.clearStartTime(index)}},model:{value:(item.startTime),callback:function ($$v) {_vm.$set(item, "startTime", $$v)},expression:"item.startTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.startTimeModal),callback:function ($$v) {_vm.$set(item, "startTimeModal", $$v)},expression:"item.startTimeModal"}},[(item.startTimeModal)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","use-seconds":""},model:{value:(item.startTime),callback:function ($$v) {_vm.$set(item, "startTime", $$v)},expression:"item.startTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){item.startTimeModal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.startTime(index)}}},[_vm._v("OK")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"stopTimedialog",refInFor:true,attrs:{"return-value":item.stopTime,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "stopTime", $event)},"update:return-value":function($event){return _vm.$set(item, "stopTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Stop Time","prepend-icon":"mdi-clock","readonly":"","clearable":"","disabled":!_vm.auto},on:{"click:clear":function($event){return _vm.clearStopTime(index)}},model:{value:(item.stopTime),callback:function ($$v) {_vm.$set(item, "stopTime", $$v)},expression:"item.stopTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.stopTimeModal),callback:function ($$v) {_vm.$set(item, "stopTimeModal", $$v)},expression:"item.stopTimeModal"}},[(item.stopTimeModal)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","use-seconds":""},model:{value:(item.stopTime),callback:function ($$v) {_vm.$set(item, "stopTime", $$v)},expression:"item.stopTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){item.stopTimeModal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.stopTime(index)}}},[_vm._v("OK")])],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(item.manual === 'on')?_c('v-btn',{staticClass:"mt-1",attrs:{"block":"","disabled":_vm.auto,"color":"success"}},[_vm._v("On")]):_vm._e(),(item.manual === 'off')?_c('v-btn',{staticClass:"mt-1",attrs:{"block":"","disabled":_vm.auto,"outlined":"","color":"indigo"},on:{"click":function($event){return _vm.buttonOn(index)}}},[_vm._v("On")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(item.manual === 'on')?_c('v-btn',{staticClass:"ml-1 mt-1",attrs:{"block":"","disabled":_vm.auto,"outlined":"","color":"indigo"},on:{"click":function($event){return _vm.buttonOff(index)}}},[_vm._v("Off")]):_vm._e(),(item.manual === 'off')?_c('v-btn',{staticClass:"ml-1 mt-1",attrs:{"block":"","disabled":_vm.auto,"color":"error"}},[_vm._v("Off")]):_vm._e()],1)],1)],1)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }