import axios from "axios";
import authHeader from "./auth-header";
const apiURL = process.env.VUE_APP_API_URL + "chart/";
class ChartService {
  async getChart(data) {
    //console.log(data);
    return axios
      .put(
        apiURL,
        {
          station: data.station,
          type: data.type,
          day: data.day,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        //console.log(res);
        /* let options = res.data.data.options;
        res.data.data.options.chart.toolbar.export.csv = this.setExport(data);
        const series = res.data.data.series;
        let payload = {
          options: options,
          series: series,
        }; */
        return res.data.data;
      })
      .catch((error) => {
        //console.log(error);
        return error
      });
  }
  async getDataChart(id) {
    console.log(id);
    return axios
      .put(apiURL + "data/" + id, { station: id }, { headers: authHeader() })
      .then((res) => {
        //console.log(res.data.data);
        return res.data.data;
      })
      .catch((error) => {
        //console.log(error);
        return error
      });
  }
  async clearData(id){
    //console.log(id)
    return axios
      .delete(apiURL + id, { headers: authHeader() })
      .then((res) => {
        //console.log(res.data.data);
        return res.data.data;
      })
      .catch((error) => {
        //console.log(error);
        return error
      });
  }
  unit(data) {
    switch (data) {
      case "Voltage":
        return "(V)";
      case "Current":
        return "(A)";
      case "Power":
        return "(W)";
    }
  }
  setExport(data) {
    //console.log(data.nameStation);
    return {
      filename: `${data.nameStation}_data_${data.type}`,
      headerCategory: "Date Time",
      headerValue: "value",
      dateFormatter(timestamp) {
        return new Date(timestamp).toLocaleString("th-TH", {
          timeZone: "UTC",
        });
      },
    };
  }
}
export default new ChartService();
